/* *{
    margin: 0px;
    padding: 0px;
    box-sizing: content-box;
} */

/* body{
    width: 100%;
    height: 100%;
} */

.entirea4page{
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
    overflow-x: hidden;
    overflow-y: auto;
}

.main-container{
	width: 210mm;
    height: 297mm;
    display: flex;
	border: 1px solid gray;
    justify-content: center;
    align-items: center;
    /* overflow-x: hidden; */
    /* overflow-y: auto; */
    background: white;
    box-sizing: border-box;
    
}

.inner-main-conatiner{
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    padding: 10px;
    /* border: 2px solid red; */
}

.voucher-header{
    display: flex;
    justify-content: space-between;
}

.voucher-header-right-content{
    display: flex;
    gap: 8px;
}

.voucher-main-heading > h1{
    font-size: 20px;
    letter-spacing: 2px;
    font-weight: 600;
    text-align: center;
}

.voucher-main-contents{
    margin-top: 15px;
}

.voucher-main-content-heading{
    background: #1F5AD6;
    display: flex;
    justify-content: space-between;
    padding: 7px 14px;
}

.voucher-main-content-heading > p{
    color: white;
    font-weight: bold;
}

.voucher-main-content-row1{
    display: flex;
    justify-content: space-between;
    padding: 7px 14px;
    margin-bottom: 2px;
}

.row1-div-1{
    width: 25%; 
    padding: 1px 0px
}

.row1-div-2{
	margin-right: 2px;
	font-weight: 700;
    font-size: 10px;
    width: 70%; 
    padding: 1px 0px;
}

.row1-div-3{
    width: 20%; 
    padding: 1px 0px
}

.voucher-main-content-row2{
    display: flex;
    justify-content: space-between;
    padding: 9px 14px;
    border-top: 2px solid rgb(209, 209, 209)
}

.row2-div-1{
    width: 29%; 
    padding: 1px 0px
}

.row2-div-2{
    width: 29%; 
    padding: 1px 0px
}

.row2-div-3{
    width: 20%; 
    padding: 1px 5px
}

.row2-div-4{
    width: 20%; 
    padding: 1px 0px
}

.voucher-main-content-row3{
    display: flex;
    justify-content: space-between;
    padding: 9px 14px;
    border-top: 2px solid rgb(209, 209, 209);
}

.row3-div-1{
    width: 29%; 
    padding: 1px 0px
}

.row3-div-2{
    width: 29%; 
    padding: 1px 0px
}

.row3-div-3{
    width: 20%; 
    padding: 1px 5px
}

.row3-div-4{
    width: 20%; 
    padding: 1px 0px
}

.voucher-main-content-row4{
    display: flex;
    justify-content: space-between;
    padding: 8px 14px;
    border-top: 2px solid rgb(209, 209, 209);
    border-bottom: 2px solid rgb(209, 209, 209)
}

.row4-div-1{
    width: 29%; 
    padding: 1px 0px
}

.row4-div-2{
    width: 29%; 
    padding: 1px 0px
}

.row4-div-3{
    width: 20%; 
    background: #F6851F;
    padding: 10px 0px
}

.row4-div-4{
    width: 20%; 
    background: #F6851F;
    padding: 10px 0px
}

.voucher-main-content2-innerheading{
    display: flex;
    justify-content: space-between;
    padding: 7px 14px;
    background: rgb(238, 231, 231);
}


.voucher-main-content2-row1{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 14px;
    border-bottom: 2px solid rgb(209, 209, 209)
}

.content2-row1-div-1{
    width: 10%; 
    padding: 1px 0px;
    text-align: center;
    /* border: 2px solid red */
}

.content2-row1-div-2{
    width: 25%;
    padding: 1px 0px;
    text-align: center;
    /* border: 2px solid red */
}

.content2-row1-div-3{
    width: 25%;
    padding: 1px 0px;
    text-align: center;
    /* border: 2px solid red */
}

.content2-row1-div-4{
    width: 10%;
    padding: 1px 0px;
    text-align: center;
    /* border: 2px solid red */
}

.content2-row1-div-5{
    width: 10%;
    padding: 1px 0px;
    text-align: center;
    /* border: 2px solid reds */
    
}

.voucher-heading1 {
    font-size: 12px;
    font-weight: 800;
    letter-spacing: 0.4px;
    color: #000;
}

.voucher-heading2 {
    font-size: 11px;
    font-weight: 700;
    letter-spacing: 0.4px;
    color: #000;
}

.voucher-heading3 {
    font-size: 12px;
    font-weight: 500;
    color: #000;
}

.voucher-heading4 {
    font-size: 10px;
    font-weight: 400;
    letter-spacing: 0.4px;
    color: #000;
}

.p-size {
    font-size: 12px;
}
