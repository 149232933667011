* {
    /* font-family: -webkit-pictograph; */
    /* font-family: 'Roboto', sans-serif;  */
    /* font-family: 'Poppins', sans-serif; */
    font-family: 'Nunito', sans-serif;
    /* font-family: 'Open Sans', sans-serif;
    font-family: 'Poppins', sans-serif; */
    /* font-family: OpenSans-Regular, Helvetica, Arial, sans-serif; */
    font-size: var(--krab-font-size);
    cursor: default;
    color: #000;
    padding: 0;
    margin: 0;
}

.ion-page {
    background: #fff;
}

i {
    color: #fff;
}

.projects-wrapper {
    opacity: 1 !important;
    z-index: 500;

}

.canvas-controls-container {
    display: flex;
    flex-direction: row;
    width: 100%;
}

.main-side-bar {
    width: var(--main-sidebar-width);
    position: relative;
    background-color: var(--krab-color-primary);
    /* display: none; */
}

.main-content-wrapper {
    height: calc(100% - var(--main-header-height));
    overflow-y: scroll;
}

.filter-header-wrapper {
    height: var(--filter-header-height);
    margin-top: 7px;
    /* padding: 10px; */
}

.featured-content-wrapper {
    height: var(--featured-container-wrapper-height);
}

.module-content-wrapper {
    width: 100%;
    height: calc(100% - var(--main-header-height));
    overflow-y: auto;
    background: #fff;
    box-shadow: 0 3px 5px 4px rgb(0 0 0 / 10%) !important;
    margin: 0.5rem;
}


.main-inner-content-wrapper {
    opacity: 1 !important;
    overflow-y: scroll;
    z-index: 100;
    /* height: calc(100% - var(--filter-header-height)); */
    height: -webkit-fill-available;
    align-content: baseline;
}

.padding5 {
    padding: 5px !important;
}

.padding0 {
    padding: 0px !important;
}

.padding10 {
    padding: 1rem !important;
}

.main-side-bar .icon {
    padding: 10px;
    border-radius: 5px;
    font-size: 1.5rem;
    cursor: pointer;
}

.main-side-bar .icon:hover {
    background: rgba(0, 0, 0, 0.3);
}

.main-side-bar .icon.--is-active {
    background: rgba(0, 0, 0, 0.3);
}

.header-content-wrapper {
    width: calc(100% - var(--main-sidebar-width));
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
}


.op-icon {
    font-size: 30px;
}

.full-width {
    width: 100%;
}

.full-height {
    height: 100%;
}

#componentLoader {
    visibility: hidden;
    background: rgba(255, 255, 255, 0.8);
    z-index: 100;
}

#componentLoader.--is-active {
    visibility: visible;
}

.dropdown-select {
    display: inline-block;
    font-weight: 400;
    color: #495057;
    background-color: transparent;
    padding: .375rem .75rem;
    font-size: 0.8rem;
    line-height: 1.5;
    position: relative;
    width: 100%;
    /* border-radius: .25rem; */
}

.dropdown-select .icon {
    display: flex;
    align-items: center;
}

.op-btn {
    display: inline-block;
    font-weight: 400;
    color: #495057;
    text-align: center;
    vertical-align: middle;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: .375rem .75rem;
    line-height: 1.5;
    border-radius: .25rem;
    transition: color 0.15s, background-color 0.15s, border-color 0.15s, box-shadow 0.15s;
}

.op-btn:focus,
.op-btn:active {
    outline: none;

}

.op-btn-primary:hover {
    color: #fff;
    background-color: #2955c8;
    border-color: #2651be;
}

.op-btn-primary {
    color: #fff;
    background-color: var(--krab-color-primary);
    border-color: var(--krab-color-primary);
}

.op-btn-warning {
    color: #fff;
    background-color: var(--krab-color-warning);
    border-color: var(--krab-color-warning);
}

.op-btn-info {
    color: #fff;
    background-color: var(--krab-color-info);
    border-color: var(--krab-color-info);
}

.op-btn-light-outline {
    color: #000;
    background-color: transparent;
    border-color: var(--krab-color-light);
}

.op-btn-light-outline:hover {
    color: #000;
    background-color: var(--krab-color-light);
    border-color: var(--krab-color-light);
}

.op-btn-success {
    color: #fff;
    background-color: var(--krab-color-success);
    border-color: var(--krab-color-success);
}

.op-btn-light {
    color: #000;
    background-color: var(--krab-color-light);
    border-color: #dbdbdb;
}

.op-btn-light:hover {
    color: #212529;
    background-color: #dbdbdb;
    border-color: #d5d5d5;
}

.shadow-light {
    box-shadow: 0 1px 1px 1px rgb(0 0 0 / 5%) !important;
}


.left-controls-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    color: white;
}

.left-menu-top {
    background-color: rgb(247, 138, 138);
    width: 5%;
    text-align: center;
}

.left-controls-top {
    background-color: #007bff;
    width: 95%;
}

.op-icon-left {
    font-size: 30px;
    padding: 10px;
}

.material {
    cursor: pointer;

}

.material:hover {
    border: 2px solid rgb(90, 115, 226);
}

.close-material-tab {
    background-color: rgb(166, 216, 240);
    color: rgb(0, 0, 0);
    display: flex;
}

.close-material-heading {
    padding: 6px;
}

.logout-button {
    display: inline-block;
    float: right;
}

.header-title {
    display: inline-block;
    margin-top: 12px;
}


.customizer-window-loader {
    background-color: #fff;
}

.overflow-x-scroll {
    overflow-x: scroll;
}

.overflow-y-scroll {
    overflow-y: scroll;
}

.customize-canvas-controls-wrapper {
    width: 100%;
    height: calc(100% - var(--main-header-height));
    display: flex;
    flex-direction: row;
    justify-content: center;
    /* background: black; */
    overflow: hidden;
    background: #fff;

}

.customize-canvas-wrapper {
    position: relative;
    overflow: initial;
    /* width: 75%; */
}

.customize-canvas-wrapper::-webkit-scrollbar {
    display: none;
}

.customize-canvas-wrapper.--is-zoom-mode {
    cursor: grab;
    overflow: auto;
}

.zindex30 {
    z-index: 30;
}

.customize-controls-wrapper {
    font-family: sans-serif;
    position: relative;
    background: #fff;
}


.customize-controls-wrapper.--is-hidden {
    display: none;
}

.customize-controls-wrapper.--is-visible {
    display: block;
}


.customize-left-wrapper {
    position: relative;
}


.customize-left-wrapper.--is-overlay {
    position: absolute;
    width: 170px !important;
    right: -30px;
    top: 0;
    height: 100vh;
    transform: translate(500px);
    z-index: 100;
    transition: all .5s;
    background: transparent;
}


.customize-controls-wrapper.--is-overlay {
    position: absolute;
    width: 240px !important;
    left: -30px;
    top: 0;
    height: 100vh;
    transform: translate(-500px);
    z-index: 100;
    transition: all .5s;
    background: transparent;
}

.customize-left-wrapper.--is-overlay .texture-label-container {
    background: transparent;
}

.customize-left-wrapper.--is-hidden {
    display: none;
}

.customize-left-wrapper.--is-visible {
    transform: translate(0);
    right: 0;
}


#controlsContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
}

/* card design */
.custom-card {
    background-color: #fff;
    background-clip: border-box;
    border-radius: 5px;
    border: 0 solid transparent;
    box-shadow: 0 0 20px rgb(0 0 0 / 8%);
}

.white-card {
    background-color: #fff;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    transition: 0.3s;
    border-radius: 5px;
}

.barfiller {
    width: 100%;
    height: 8px;
    background: #fff;
    position: relative;
    border-radius: 30px;
}

.barfiller .tipWrap {
    display: inline;
}

.barfiller .tip {
    color: #508FF4;
    left: 43.2265px;
    transition: left 2s ease-in-out 0s;
}

.barfiller .fill {
    display: block;
    position: relative;
    width: 0px;
    height: 100%;
    z-index: 1;
    border-radius: 30px;
    background: rgb(255, 191, 67);
    width: 66.664px;
    transition: width 2s ease-in-out 0s;
}

.barfiller .barhint {
    position: absolute;
    bottom: 125%;
    right: 0;
    padding: 5px;
    color: var(--krab-color-dark);
    font-size: 0.6rem;
    font-weight: bold;
}

/* modal css */
.modal-header {
    height: 10%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid #EEEDED;
    background: #fff;
}

.modal-body {
    position: relative;
    height: 80%;
    overflow: auto;
    background: #fff;

}

.circle-icon-container {
    background: rgba(233, 229, 229, 0.7);
    border-radius: 50%;
    color: gray;
    border: 1px solid rgba(233, 229, 229, 0.7);
    padding: 10px 13px;
    font-size: 0.7rem;
}

.custom-pop-over {
    position: absolute;
    z-index: 5;
    /* top: calc(100% - 4px); */
    bottom: 40px;
    right: 0;
    border-radius: 5px;
    border-top: none;
    box-shadow: 0 8px 8px rgb(10 10 10 / 10%), 0 0 0 1px rgb(10 10 10 / 10%);
    display: block;
    opacity: 0;
    padding-bottom: 0.5rem;
    padding-top: 0.5rem;
    pointer-events: none;
    transform: translateY(-5px);
    transition-duration: 86ms;
    transition-property: opacity, transform;
    background-color: #ffffff;
}

.custom-pop-over.--is-active {
    opacity: 1;
    pointer-events: all;
}

.custom-pop-over ul {
    list-style: none;
    padding: 0;
    margin: 3px 0;
}

.custom-pop-over ul li {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 0.85rem;
    white-space: nowrap;
    padding: 0.5em 1rem;
    cursor: pointer;
}

.custom-pop-over ul li span {
    margin-top: -3px;
    margin-left: 10px;
}

.custom-pop-over ul li:hover {
    background: #efefef;
}

.border-left {
    border-left: 1px solid rgba(233, 229, 229, 0.7);
}

.border-right {
    border-right: 1px solid rgba(233, 229, 229, 0.7);
}

.border-bottom {
    border-bottom: 1px solid rgba(233, 229, 229, 0.7);
}

.border-top {
    border-top: 1px solid rgba(233, 229, 229, 0.7);
}

.border {
    border: 1px solid rgba(233, 229, 229, 1);
}

.light-background {
    background: rgba(233, 229, 229, 0.4);
}

.display-flex-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.display-flex-column {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
}

.color-light {
    color: rgba(172, 168, 168, 0.8);
}

.color-dark {
    color: var(--krab-color-dard) !important;
}

.color-primary {
    color: var(--krab-color-primary) !important;
}

.color-success {
    color: var(--krab-color-success) !important;
}

.color-danger {
    color: var(--krab-color-danger) !important;
}

.bg-color-light {
    background: var(--krab-color-light) !important;
}

.bg-color-success {
    background: var(--krab-color-success) !important;
}

.bg-color-primary {
    background: var(--krab-color-primary) !important;
}

.bg-color-dark {
    background: var(--krab-color-dark) !important;
}

.bg-color-warning {
    background: var(--krab-color-warning) !important;
}

.bg-color-danger {
    background: var(--krab-color-danger) !important;
}

.bg-color-active {
    background: var(--krab-color-active) !important;
}

.bg-color-comingsoon{
    background: rgb(235, 227, 227) !important;
}

.font-small {
    font-size: 0.8rem !important;
}

.font-large {
    font-size: 2rem !important;
}

.font-medium {
    font-size: 1.2rem !important;
}

.font-normal {
    font-size: 1rem !important;
}


.status-container {
    width: 20px;
    height: 20px;
    text-align: center;
    border-radius: 5px;
}

.padding-start {
    padding-left: 10px;
}

.flex1 {
    flex: 1;
}

.custom-tabs-wrapper {
    display: flex;
    flex-direction: row;

}

.custom-tabs-wrapper .tab {
    padding: 14px;
    font-size: 0.9rem;
    font-weight: 550;
    flex: 50%;
    text-align: center;
    opacity: 0.5;
    cursor: pointer;
    color: gray;
    text-transform: initial;
}

.custom-tabs-wrapper .tab:hover {
    opacity: 1;
    color: black;
}

.position-relative {
    position: relative;
}

.custom-upload-input {
    width: 160px;
    padding: 12px;
    display: flex;
    font-size: 0.8rem;
    overflow: hidden;
}

.overflow-hidden {
    overflow: hidden;
}

.uppercase {
    text-transform: uppercase !important;
}

.custom-tabs-wrapper .tab.--is-active {
    opacity: 1;
    /* border-bottom: 1px solid black; */
    color: black;
    background: rgba(233, 229, 229, 0.4);
}

.color-white {
    color: #fff;
}

.color-black {
    color: #000;
}

.background-white {
    background: #fff !important;
}

.background-primary {
    background: var(--krab-color-primary);
}

.full-height-width {
    height: 100%;
    width: 100%;
}

.modal-footer {
    height: 10%;
    display: flex;
    justify-content: center;
    padding: 0 10px;
    align-items: center;
    background: #fff;
    border-top: 1px solid rgba(233, 229, 229, 0.7);
}

.modal-header .modal-close {
    font-size: 2rem;
    position: absolute;
    right: 0;
    top: 0;
    padding: 10px;
}

.modal-close-icon {
    position: absolute;
    font-size: 1.5rem;
    top: 10px;
    right: 10px;
}

.pricing-modal-footer {
    justify-content: start;
}

.pricing-modal-footer .icon {
    padding: 0 5px;
}

.pricing-modal-footer>div {
    flex: 50%;
}


.pricing-footer-content-right {
    display: flex;
    justify-content: flex-end;
}

.flex-end {
    justify-content: flex-end;
}

.custom-row {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
}


.custom-col-50 {
    flex: 50%;
    position: relative;
    height: 100%;
}

.custom-col-70 {
    flex: 70%;
    position: relative;
    height: 100%;
}

.custom-col-60 {
    flex: 60%;
    position: relative;
    height: 100%;
}

.custom-col-20 {
    flex: 20%;
    position: relative;
    height: 100%;
}

.custom-col-10 {
    flex: 10%;
    position: relative;
    height: 100%;
}

.project-action-modal .modal-wrapper {
    --width: 800px;
    --height: 500px;
    border: 1px solid #dadad8;
    border-radius: 10px;
    box-shadow: 0 3px 16px rgb(0 0 0 / 20%);
}

.confirmation-modal .modal-wrapper {
    --width: 400px;
    --height: 300px;
}

.vertical-modal .modal-wrapper {
    --width: 450px;
    --height: 95%;
}


.small-x-modal .modal-wrapper {
    --width: 100%;
    --height: 100%;
    --max-width: 45%;
    --max-height: 65%;
}

.medium-x-modal .modal-wrapper {
    --width: 100%;
    --height: 100%;
    --max-width: 60%;
    --max-height: 75%;
}

.large-x-modal .modal-wrapper {
    --width: 100%;
    --height: 100%;
    --max-width: 85%;
    --max-height: 90%;
}

.medium-y-modal .modal-wrapper {
    --width: 100%;
    --height: 100%;
    --max-width: 600px;
    --max-height: 95%;
}

.small-y-modal .modal-wrapper {
    --width: 100%;
    --height: 100%;
    --max-width: 35%;
    --max-height: 80%;
}

.medium-y-modal,
.medium-x-modal,
.small-x-modal,
.small-y-modal,
.vertical-modal,
.large-x-modal {
    background: rgba(84, 82, 82, 0.5);
}

.modal-vertical .modal-wrapper {
    --width: 50%;
    --height: 95%;
    border: 1px solid #dadad8;
    border-radius: 10px;
    box-shadow: 0 3px 16px rgb(0 0 0 / 20%);
}

.pointer-none {
    pointer-events: none;
}

.dropdown-container {
    position: relative;
    height: 100%;
    width: 200px;
}

.dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    display: none;
    z-index: 100;
    float: left;
    width: 100%;
    padding: .65rem 0;
    margin: .125rem 0 0;
    font-size: .88rem;
    color: #495057;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.15);
    box-shadow: 0 0.46875rem 2.1875rem rgb(4 9 20 / 3%), 0 0.9375rem 1.40625rem rgb(4 9 20 / 3%), 0 0.25rem 0.53125rem rgb(4 9 20 / 5%), 0 0.125rem 0.1875rem rgb(4 9 20 / 3%);
    padding: .65rem;
    max-height: 300px;
    overflow-y: scroll;
    overflow-x: hidden;
}

.dropdown-menu.--is-active {
    display: block;
}

/* 
.dropdown-menu-header {
    color: #fff;
    margin-top: -.65rem;
    margin-bottom: .65rem;
    position: relative;
    z-index: 6;
}

.dropdown-header {
    display: block;
    padding: .65rem 1.5rem;
    margin-bottom: 0;
    font-size: .968rem;
    color: #6c757d;
    white-space: nowrap;
} */

.dropdown-menu .dropdown-item {
    font-size: .88rem;
    display: flex;
    align-items: center;
    transition: background-color 0.3s ease, color 0.3s ease;
    cursor: pointer;
    z-index: 6;
    position: relative;
}

.dropdown-item {
    display: block;
    width: 100%;
    padding: .4rem 0.5rem;
    clear: both;
    font-weight: 400;
    color: #212529;
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
}

.dropdown-item.active,
.dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: var(--krab-color-ternary);
}

.dropdown-item:hover,
.dropdown-item:focus {
    color: #16181b;
    text-decoration: none;
    outline: none;
    background-color: var(--krab-color-active);
}

.dropdown-toggle::after {
    position: absolute;
    right: 5px;
    opacity: .8;
    top: 50%;
    display: inline-block;
    content: "";
    border-top: .3em solid;
    border-right: .3em solid transparent;
    border-bottom: 0;
    border-left: .3em solid transparent;
    transform: translate(-50%, 0);
}

.text-start {
    text-align: start;
}

.space-between {
    justify-content: space-between;
}

.space-around {
    justify-content: space-around;
}


.text-center {
    text-align: center;
}


.detination-details {
    width: auto;
    /* Set a fixed width */
    display: -webkit-box;
    -webkit-line-clamp: 2;
    /* Number of lines to display before ellipsis */
    -webkit-box-orient: vertical;
    display: -moz-box;
    -moz-line-clamp: 2;
    /* Number of lines to display before ellipsis */
    -moz-box-orient: vertical;
    display: -ms-box;
    -ms-line-clamp: 2;
    /* Number of lines to display before ellipsis */
    -ms-box-orient: vertical;
    overflow: hidden;
    /* Hide overflowing content */
}

.no-shadow {
    box-shadow: none !important;
}


.table {
    width: 100%;
    background-color: rgba(0, 0, 0, 0);
    border-collapse: collapse;
}



.table th,
.table td {
    padding: 1rem;
    vertical-align: inherit;
    border-top: 1px solid #e9ecef;
    text-align: start;
}

.table thead {
    background: rgb(244, 246, 248);
}

.table th {
    color: rgb(99, 115, 129);
}



.search-container {
    /* height: 10%; */
    position: relative;
    display: flex;
    flex-direction: row;
    padding: 0;
    width: 300px;
}

.search-container .icon {
    background: none !important;
    color: black !important;
    font-size: 18px;
    border: none;
    cursor: pointer;
}

.search {
    background-position: 10px 10px;
    background-repeat: no-repeat;
    width: 100%;
    font-size: 0.9rem;
    padding: 7px 10px;
    border: 1px solid #ddd;
}

.moodboard-modal .modal-wrapper {
    --width: 75%;
    --height: 90%;
}

.model-viewer-modal .modal-wrapper {
    --width: 80%;
    --height: 90%;
    border: 1px solid #dadad8;
    border-radius: 10px;
    box-shadow: 0 3px 16px rgb(0 0 0 / 20%);
}

.top-right {
    position: absolute;
    top: 0;
    right: 0;
    padding: 10px;
}

.top-left {
    position: absolute;
    top: 0;
    left: 0;
    padding: 10px;
}

.box-shadow {
    box-shadow: 0 0.46875rem 2.1875rem rgb(4 9 20 / 3%), 0 0.9375rem 1.40625rem rgb(4 9 20 / 3%), 0 0.25rem 0.53125rem rgb(4 9 20 / 5%), 0 0.125rem 0.1875rem rgb(4 9 20 / 3%);
}

.no-wrap {
    flex-wrap: nowrap;
}

.custom-row {
    display: flex;
    flex-direction: row;
    width: 100%;
}


.custom-col-30 {
    width: 30%;
}

.custom-col-80 {
    width: 80%;
}

.coming-soon {
    position: relative;
    background-color: rgba(233, 229, 229, 0.7);
    pointer-events: none;
    opacity: 0.8;
}

.right-middle {
    position: absolute;
    top: 50% !important;
    right: 10px;
    transform: translate(0, -50%);
}

.left-middle {
    position: absolute;
    top: 50%;
    left: 10px;
    transform: translate(0, -50%);
}

.bottom-middle {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0);
}

.bottom-right {
    position: absolute;
    bottom: 0;
    right: 0;
}

.bottom-left {
    position: absolute;
    left: 0;
    bottom: 0;
}

.middle {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.top-middle {
    position: absolute;
    top: 10px;
    left: 50%;
    transform: translate(-50%, 0);
}

.right-bottom {
    position: absolute;
    bottom: 0;
    right: 0;
    padding: 10px;
}

.width50 {
    width: 50%;
}


.left-bottom {
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 10px;
}

.form-action-modal .modal-wrapper {
    --width: 500px;
    --height: 90%;
    border: 1px solid #dadad8;
    border-radius: 10px;
    box-shadow: 0 3px 16px rgb(0 0 0 / 20%);
}

.border-danger {
    border-color: var(--krab-color-danger) !important;
}

.details-modal .modal-wrapper {
    --width: 50%;
    --height: 90%;
    border: 1px solid #dadad8;
    border-radius: 10px;
    box-shadow: 0 3px 16px rgb(0 0 0 / 20%);
}

.coming-soon::after {
    /* content: "Coming soon"; */
    position: absolute;
    top: 0;
    left: 0;
    padding: 10px;
    font-size: 0.7rem;
}

.tooltip {
    position: relative;
}

.tooltiptext {
    visibility: hidden;
    width: 120px;
    background-color: #000;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    left: 50%;
    bottom: 115%;
    transform: translate(-50%, 0);
    animation-name: tootltopAnimation;
    animation-duration: 1s;
    animation-iteration-count: infinite;
}

.tooltiptext.--is-active {
    visibility: visible;
}

.tooltip .tooltiptext::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #000 transparent transparent transparent;
}

.horizontal-scroll-wrapper {
    position: relative;
    width: 100%;
    overflow-x: hidden;
    overflow-y: hidden;
    white-space: nowrap;
    transition: all 0.2s;
    transform: scale(0.98);
    will-change: transform;
    user-select: none;
    cursor: pointer;
    opacity: 1 !important;
    pointer-events: all !important;
}

.horizontal-scroll-wrapper:active {
    background: rgba(255, 255, 255, 0.3);
    cursor: grabbing;
    cursor: -webkit-grabbing;
    transform: scale(1);
}

@keyframes tootltopAnimation {
    0% {
        bottom: 115%;
    }

    50% {
        bottom: 135%;
    }

    100% {
        bottom: 115%;
    }
}

/* .tooltip:hover .tooltiptext {
    visibility: visible;
  } */

.icon-text-container {
    display: flex;
}

.disable {
    pointer-events: none;
    cursor: auto;
    opacity: 0.5;
}

.center-aligned-row {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.center-aligned-column {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.hide-scrollbar::-webkit-scrollbar {
    display: none;
}


.op-scrollbar::-webkit-scrollbar {
    width: 5px;
}


@-webkit-keyframes fadein {
    from {
        bottom: 0;
        opacity: 0;
    }

    to {
        bottom: 30px;
        opacity: 1;
    }
}

@keyframes fadein {
    from {
        bottom: 0;
        opacity: 0;
    }

    to {
        bottom: 30px;
        opacity: 1;
    }
}

@-webkit-keyframes fadeout {
    from {
        bottom: 30px;
        opacity: 1;
    }

    to {
        bottom: 0;
        opacity: 0;
    }
}

@keyframes fadeout {
    from {
        bottom: 30px;
        opacity: 1;
    }

    to {
        bottom: 0;
        opacity: 0;
    }
}

select {
    width: 100%;
    border-color: #ddd;
    border-width: 1px;
    padding: 8px 22px;
}

select:focus {
    outline: none;
}

input:focus {
    outline: none;
}

.badge-container {
    padding: 5px 10px;
    font-size: 0.8rem;
    font-family: "Public Sans", sans-serif;
    font-weight: 700;
    height: 22px;
    min-width: 22px;
    line-height: 0;
    border-radius: 6px;
    cursor: default;
    align-items: center;
    white-space: nowrap;
    display: inline-flex;
    justify-content: center;
    padding: 0px 8px;
}

.badge-container.primary {
    color: rgb(12, 83, 183);
    background-color: rgba(24, 144, 255, 0.16);

}

.badge-container.success {
    color: rgb(34, 154, 22);
    background-color: rgba(84, 214, 44, 0.16);
}

.badge-container.warning {
    color: rgb(183, 129, 3);
    background-color: rgba(255, 193, 7, 0.16);
}

.badge-container.danger {
    color: rgb(183, 33, 54);
    background-color: rgba(255, 72, 66, 0.16);
}

.badge-container.dark {
    color: rgb(99, 115, 129);
    background-color: rgba(145, 158, 171, 0.16);
}


.hide-in-desktop {
    display: none !important;
}

.hide-in-mobile {
    display: block;
}

ul.error li {
    color: var(--krab-color-danger);
}


.small-box-modal .modal-wrapper {
    --width: 350px;
    --height: 320px;
    border: 1px solid #dadad8;
    border-radius: 10px;
    box-shadow: 0 3px 16px rgb(0 0 0 / 20%);
}

.padding-container {
    padding: 0 3rem;
}

.service-list-with-scroll{
    display: none !important;
}

/* .service-list-filter {
    display: none !important;
} */


.header-content {
    height: 4.5rem;
    border-bottom: 1px solid #eee;
    position: relative;
    background-color: #f0eeee;
}

.body-content {
    height: calc(100% - 9rem);
    overflow-y: auto;
}

.body-content-no-footer {
    height: calc(100% - 4.5rem);
}

.footer-content {
    height: 4.5rem;
}

.bg-color-coming-soon{
    background-color: gray;
    color: white;
}

/* .modal-shadow.sc-ion-modal-ios{
    background: rgba(0,0,0,0.7);
} */

@media (min-width: 567px){
    .service-margin-container{
        height: 70% !important;
    }
}


@media (max-width:480px) {

    .main-side-bar {
        display: none;
    }

    .hide-in-desktop {
        display: flex !important;
    }

    .padding-container {
        padding: 0 1rem;
    }

    .search-input {
        height: 32px;
    }

    .customize-canvas-controls-wrapper {
        flex-direction: column !important;
    }

    .custom-row {
        flex-direction: column;
    }

    .custom-col-20,
    .custom-col-30,
    .custom-col-80 {
        width: 100%;
    }

    .left-menu-top {
        width: 20%;
    }

    .left-controls-top {
        width: 80%;
    }

    .view {
        font-size: 0.7rem;
        padding: 8px;
    }

    .main-content-wrapper {
        --project-container-width: 190px;
        --project-container-height: 230px;
        --project-container-image-height: 150px;
    }

    .dropdown-container {
        width: 130px;
    }

    .search-container {
        width: 150px;
    }

    .hide-in-mobile {
        display: none !important;
    }

    .vertical-modal .modal-wrapper {
        --width: 100%;
        --height: 100%;
    }

    .medium-x-modal .modal-wrapper,
    .medium-y-modal .modal-wrapper {
        --width: 100%;
        --height: 100%;
        --max-width: 100%;
        --max-height: 100%;
    }

    /* //////////////// */

    .query-suggestion-cont {
        /* background-color: red; */
        display: flex;
        flex-direction: column;
        align-items: center !important;
    }

    .query-cont {
        width: 100% !important;
    }

    .suggestion {
        /* background-color: yellow; */
        display: flex;
        justify-content: flex-start !important;
        width: 100% !important
    }

    .suggessions-container {
        margin-top: 0px !important;
    }

    .suggessions-container .margin-bottom {
        margin-bottom: 10px !important;
    }

    .suggessions-container .heading {
        font-size: larger !important;
    }

    .suggessions-container .see-all {
        font-size: larger !important
    }

    .suggestions-cont {
        display: flex;
        align-items: center;
        overflow-x: hidden;
    }

}


@media only screen and (min-width: 481px) and (max-width: 1073px) {

    .search-input {
        height: 32px;
    }

    .project-action-modal .modal-wrapper {
        --width: 80%;
        --height: 90%;
        border: 1px solid #dadad8;
        border-radius: 10px;
        box-shadow: 0 3px 16px rgb(0 0 0 / 20%);
    }

    .modal-header .modal-close {
        font-size: 1.5rem;
        padding: 5px;
    }

    .modal-footer .button {
        height: 25px;
        font-size: 0.7rem;
    }

    .search-container {
        width: 200px;
    }

    .main-content-wrapper {
        --project-container-width: 190px;
        --project-container-height: 230px;
        --project-container-image-height: 150px;
    }

    .dropdown-container {
        width: 150px;
    }

    /* ////////////// */

    .query-suggestion-cont {
        /* background-color: red; */
        display: flex;
        flex-direction: column;
        align-items: center !important;
    }

    .query-cont {
        width: 100% !important;
    }

    .suggestion {
        /* background-color: yellow; */
        display: flex;
        justify-content: flex-start !important;
        width: 100% !important
    }

    .suggessions-container {
        margin-top: 0px !important;
    }

    .suggessions-container .margin-bottom {
        margin-bottom: 10px !important;
    }

    .suggessions-container .heading {
        font-size: larger !important;
    }

    .suggessions-container .see-all {
        font-size: larger !important
    }

    .suggestions-cont {
        display: flex;
        align-items: center;
        overflow-x: hidden;
    }

    .service-list-filter {
        display: inline !important;
    }

    .service-list-without-scroll {
        display: none !important;
    }

}

@media (max-width:480px) {
    .query-suggestion-cont {
        /* background-color: red; */
        display: flex;
        flex-direction: column;
        align-items: center !important;
    }

    .query-cont {
        width: 100% !important;
    }

    .suggestion {
        /* background-color: yellow; */
        display: flex;
        justify-content: flex-start !important;
        width: 100% !important
    }

    .suggessions-container {
        margin-top: 0px !important;
    }

    .suggessions-container .margin-bottom {
        margin-bottom: 10px !important;
    }

    .suggessions-container .heading {
        font-size: larger !important;
    }

    .suggessions-container .see-all {
        font-size: larger !important
    }

    .suggestions-cont {
        display: flex;
        align-items: center;
        overflow-x: hidden;
    }

    .service-list-with-scroll{
        display: inline !important;
    }

    .service-list-without-scroll {
        display: none !important;
    }

}

@media (max-width:850px) {
    .main-side-bar {
        display: none;
    }

    .hide-in-desktop {
        display: flex !important;
    }

    .padding-container {
        padding: 0 1rem;
    }

    .search-input {
        height: 32px;
    }

    .customize-canvas-controls-wrapper {
        flex-direction: column !important;
    }

    .custom-row {
        flex-direction: column;
    }

    .custom-col-20,
    .custom-col-30,
    .custom-col-80 {
        width: 100%;
    }

    .left-menu-top {
        width: 20%;
    }

    .left-controls-top {
        width: 80%;
    }

    .view {
        font-size: 0.7rem;
        padding: 8px;
    }

    .main-content-wrapper {
        --project-container-width: 190px;
        --project-container-height: 230px;
        --project-container-image-height: 150px;
    }

    .dropdown-container {
        width: 130px;
    }

    .search-container {
        width: 150px;
    }

    .hide-in-mobile {
        display: none !important;
    }

    .vertical-modal .modal-wrapper {
        --width: 100%;
        --height: 100%;
    }

    .medium-x-modal .modal-wrapper,
    .medium-y-modal .modal-wrapper {
        --width: 100%;
        --height: 100%;
        --max-width: 100%;
        --max-height: 100%;
    }

    .header-content-wrapper {
        width: 100% !important;
    }
}

@media (max-width:650px) {
    .captcha-div-main {
        width: 70% !important;
    }
}

@media (max-width:544px) {
    .captcha-div-main {
        width: 80% !important;
    }
}

@media (max-width:480px) {
    .captcha-div-main {
        width: 100% !important;
        /* border: 2px solid red */
    }
}

@media (max-width:483px) {
    .captcha-div-main .captcha-button {
        padding: 6px;
    }

    .captcha-div-main .search-input {
        font-size: 11.5px !important;
    }

    .label {
        font-size: 12px !important;
    }

    .label .heading {
        font-size: 12px !important;
        margin-bottom: 4px !important;
    }

}

@media (max-width:385px) {

    .captcha-div-main .captcha-comp {
        /* border: 1px solid red; */
        width: 120px !important
    }

    .header-content-wrapper .header-2-btns {
        /* border: 3px solid red !important; */
        margin-right: 3px;
    }

    .header-content-wrapper .header-2-btns .create-query-btn .button2 {
        align-items: center;
        padding: 8px;
        width: 100px !important;
    }

    .header-content-wrapper .header-2-btns .create-query-btn .button2 .text {
        font-size: 10px;
    }

    .header-content-wrapper .header-2-btns .create-query-btn .button2 .icon {
        height: 13px;
    }

}