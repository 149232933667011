.login-page-main-wrapper {

    /* background-repeat: no-repeat; */
    background-size: cover;
    /* background-position:center; */
    /* background: rgb(2,0,36); */
    /* background: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(9,9,121,0.5) 0%, rgba(0,212,255,0.5) 100%); */
}

.login-page-main-wrapper .container {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    width: 85%;
    height: 90%;
}

.login-page-left {
    height: 100%;
    width: 45%;
    background-repeat: no-repeat;
    background-size: cover;
    /* border: 2px solid blue; */
}

.login-page-left .description {
    padding-right: 10rems;
    line-height: 20px;
    flex-direction: ;
}

/* .login-page-left .background{
    background: rgb(63,94,251);
    background: linear-gradient(90deg, rgba(63,94,251,1) 0%, rgba(252,70,107,0.5) 100%);
} */

.login-page-right {
    height: 100%;
    width: 55%;
    margin: 5px;
}

.login-page-wrapper {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.login-container {
    height: 110%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0px 10px;
    width: 70%;
    /* border: 2px solid red; */
}

/* .login-container .login-button{
    padding: 1rem !important;
} */

.register-container {
    padding: 20px 30px;
    width: 70%;
}

.login-container .form-label-input-container {
    padding: 0.7vw 0;
}

.register-container .label-container {
    padding: 5px 0;
}

.register-container .form-label-input-container {
    padding: 10px 5px;
}

.login-container .form-label-input-container .input-label {
    color: gray;
}


.login-container .form-label-input-container .input-item {
    --padding-start: 0;
    --min-height: 10px;
}


.login-container .login-button {
    height: calc(3.25rem + 2px);
}


.error {
    color: red;
}

.register-container .heading {
    color: var(--krab-color-primary);
}

/* -----------my css--------- */
.login-left {
    height: 60%;
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    /* border: 2px solid black;  */
}

.ion-card-logo {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    box-shadow: none !important;

}

.ion-card-img {
    height: 220px;
}

.img-card {
    height: 220px;
}

.ion-card {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    /* align-items: center; */
    padding: 5px;
    box-shadow: none !important;

}

.ion-card-header {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.ion-card-subtitle {
    font-size: 1vw;
    font-weight: 500;
    line-height: 20px;
    font-family: "Nunito", sans-serif;
}

.ion-card-title {
    font-size: 30px;
    font-weight: 700;
    line-height: 30px;
    font-family: "Nunito", sans-serif;
}

.g-recaptcha {
    transform: scale(0.9);
    transform-origin: 0 0;
}

/* .Forget-passoword-popup .modal-wrapper{
    --width: 100%;
    --height: 100%;
} */


/*---------- my css -----------*/

@media (max-width:1025px) {
    .login-container {
        box-shadow: none !important;
        background: none !important;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        /* border: 2px solid blue */
    }

    .login-page-left {
        display: none;
    }

    .login-page-right {
        width: 100%;
        box-shadow: none;
    }

    .login-page-main-wrapper .container {
        /* padding: 0px !important; */
        flex-direction: column;
        height: 90%;
        /* border: 3px solid red */
    }

    /*--------- my-css---------- */
    .go-to-registerpage {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
    }

    /* .register-container{
        width: 100%;
        padding: 0;  
    } */

    /* .register-container .heading {
        
        padding: 10px;
    } */


    .g-recaptcha {
        transform: scale(0.9);
        transform-origin: 0 0;
    }

}

@media (max-width:850px) {
    .Forget-passoword-popup .modal-wrapper {
        --width: 100%;
        --height: 100%;
        --max-width: 85%;
        --max-height: 90%;
    }
}

@media (max-width:720px) {
    .login-container {
        box-shadow: none !important;
        background: none !important;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        /* border: 2px solid blue; */
        /* padding: 10px !important; */
        height: 100%;
    }

}

@media (max-width:540px) {
    .g-recaptcha {
        transform: scale(0.7);
        transform-origin: 0 0;
    }
}

@media (max-width:450px) {
    .g-recaptcha {
        transform: scale(0.6);
        transform-origin: 0 20px;
    }

    .Forget-passoword-popup .modal-wrapper {
        --width: 100%;
        --height: 100%;
        --max-width: 90%;
        --max-height: 75%;
    }

    .register-container .heading {
        font-size: 12px;
    }
}

@media (max-width:395px) {
    .g-recaptcha {
        transform: scale(0.5);
        transform-origin: 0 20px;
    }
    .register-container .heading {
        font-size: 10px;
    }

    .go-to-registerpage{
        font-size: 12px;
    }
}

@media screen and (min-width:481px) and (max-width:1024px) {

    .login-container {
        padding: 20px 10px;
    }
}



ion-modal#example-modal {
    /* --height: 50%; */
    --width: 50%;
    /* --width: 30% */
    --border-radius: 10px;
    /* --box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1); */
}