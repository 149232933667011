
.button-gradient {background-image: linear-gradient(to right, #FF8008 0%, #FFC837  51%, #FF8008  100%)}
.button-gradient {
    padding: 15px 45px;
    text-align: center;
    text-transform: uppercase;
    transition: 0.5s;
    background-size: 200% auto;
    color: white;            
    box-shadow: 0 0 20px #eee;
    border-radius: 10px;
    display: block;
}

.button-gradient:hover {
    background-position: right center; /* change the direction of the change here */
    color: #fff;
    text-decoration: none;
}



.button-gradient2 {
  background-image: linear-gradient(to right, #4776E6 0%, #8E54E9  51%, #4776E6  100%)
}
.button-gradient2 {
   padding: 15px 45px;
   text-align: center;
   text-transform: uppercase;
   transition: 0.5s;
   background-size: 200% auto;
   color: white;            
   box-shadow: 0 0 20px #eee;
   border-radius: 10px;
   display: block;
 }

 .button-gradient2:hover {
   background-position: right center; /* change the direction of the change here */
   color: #fff;
   text-decoration: none;
 }

         


 .notification {
  text-decoration: none;
  padding-left: 7px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* .notification:hover {
  background: red;
} */

.notification .badge {
  position: absolute;
  top: -5px;
  right: -2px;
  padding: 1px 5px;
  border-radius: 50%;
  background-color: var(--krab-color-danger);
  color: white;
  font-size: 0.8rem;
}

.custom-badge{
  width: fit-content;
  height: fit-content;
  padding: 4px 10px;
  color: white;
  border-radius: 5px;
  font-size: 0.8rem;
  font-weight: 400;
  text-transform: capitalize;
}

.gradient-primary{
background-image: linear-gradient(to right, #4776E6 0%, #8E54E9  51%, #4776E6  100%)
}

.gradient-warning {background-image: linear-gradient(to right, #FF8008 0%, #FFC837  51%, #FF8008  100%)}

.heading {
  font-weight: 400;
  color: rgba(13, 27, 62, 0.7);
}
.header-heading{
  font-size: 1.2rem;
  font-weight: 700;
}
 