.services-header{
    height: 65%;
    overflow: hidden;
}

.services-header img{
    object-fit: contain;
    border-radius: 0 0 40px 40px;
}


.create-query-wrapper .query-input{
    background-color: #fff;
    padding: 8px;
    border: 1px solid rgb(131, 128, 128);
    font-weight: 400;
    font-size: 11px;
    outline: none;
    border-radius: 5px;
    margin-top: 10px;
}

.create-query-wrapper .label{
    /* color: rgb(64, 63, 63); */
    font-weight: 600; 
    font-size: 15px; 
    margin: 5px 0;
}
::-webkit-calendar-picker-indicator{
    background-color: #ffffff;
    padding: 5px;
    cursor: pointer;
    border-radius: 3px;
}

/* .create-query-header{
    height: 18%;
    background-image: linear-gradient(to right, #4776E6 0%, #8E54E9  51%, #4776E6  100%)
} */

#roomSelectorWrapper,#hotelOptionsWrapper{
    position: fixed;
    top: 100%;
    /* transform: translate(-50%,-50%); */
    left: 0;
    transition: all 0.5s;
    width: 100%;
    height: 100%;
    z-index: 1001;
}

#roomSelectorWrapper .container,#hotelOptionsWrapper .container{
    width: 90%;
    max-height: 100%;
    background-color: #fff;
}

#roomSelectorWrapper.--is-active,#hotelOptionsWrapper.--is-active{
    top: 0;
    left: 0;
    /* transform: translate(-50%,-50%); */
    background: rgba(0,0,0,0.2);
}

.rmdp-input{
    opacity: 1;
    height: inherit;
    height: 35px !important;
    width: 100%;
    background-color: #fff !important;
}

.rmdp-container {
    display: flex !important;
}

@media (max-width:480px){

}
