:root {
  --tab-container-height: 75px;
}

.customize-options-container {
  /* background-color: rgba(207, 207, 207, 0.7); */
  /* box-shadow: 1px 1px 5px #f0f0f0; */
  overflow-y: auto;
  height: 100%;
  /* font-family: 'Roboto', sans-serif; */
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  text-transform: uppercase;
  letter-spacing: 1.2px;

  border-radius: 5px;
  padding: 0;
  border: 0 solid transparent;
  -webkit-box-shadow: 0 0 20px rgb(0 0 0 / 8%);
  box-shadow: 0 0 20px rgb(0 0 0 / 8%);
  background-clip: border-box;
  background: #fafafa;
  font-size: 0.9rem;
  /* background: #E8E9EF; */
}

.object-heading {
  padding: 10px 5px;
  /* background-color: rgba(240, 234, 234, 0.8); */
  font-size: 0.9rem;
  font-weight: bold;
  /* box-shadow: 1px 1px 5px #f0f0f0; */
  /* border: 1px solid rgb(240, 234, 234); */
}

.product-heading {
  padding: 10px 5px;
  color: gray;
  font-size: 0.8rem;
  font-weight: bold;
}

.controls-header {
  position: relative;
  bottom: 0;
  background-color: rgb(241, 238, 238);
  display: flex;
  flex-direction: row;
  width: 100%;
  background-color: white;
  /* box-shadow: 0 3px 2px #bdbdbd; */
  border-bottom: 1px solid rgb(240, 234, 234);

  /* overflow-x: scroll; */
}

#leftSidebarClose {
  display: none;
}

#leftSidebarClose.--is-active {
  display: block;
}

.home-button-mobile {
  visibility: hidden;
}

.toggle-left-wrapper {
  border-radius: 5px;
  transition: all 0.5s;
  width: -moz-fit-content;
  width: fit-content;
  padding: 7px 9px;
  text-align: center;
  background: rgba(255, 255, 255, 0.8);
  color: #fff;
  cursor: pointer;
  box-shadow: 0 0.46875rem 2.1875rem rgb(4 9 20 / 3%),
    0 0.9375rem 1.40625rem rgb(4 9 20 / 3%),
    0 0.25rem 0.53125rem rgb(4 9 20 / 5%), 0 0.125rem 0.1875rem rgb(4 9 20 / 3%);
  margin: 0 5px;
  /* border: 1px solid var(--krab-color-light); */
  transform: translate(0);
  visibility: hidden;
  z-index: 99;
  bottom: 5px;
}

#controlsDragIcon {
  transform: translate(0, -100%);
  z-index: 101;
}

.toggle-controls-wrapper {
  position: absolute;
  visibility: hidden;
  z-index: 30;
}

.toggle-left-wrapper.--is-visible-left {
  visibility: visible;
  left: 0;
}
.toggle-left-wrapper.--is-visible-right {
  visibility: visible;
  /* right: 0; */
}

.toggle-controls-wrapper.--is-visible-right {
  visibility: visible;
  top: 5px;
  right: 5px;
}

.toggle-controls-wrapper.--is-visible-bottom-middle {
  visibility: visible;
  bottom: 10px;
  left: 50%;
  transform: translate(-50%, 0);
}

.toggle-controls-wrapper.--is-visible-left {
  visibility: visible;
  top: 10px;
  left: 10px;
}

.toggle-left-wrapper.--is-active-left {
  /* transform: translate(170px); */
  /* visibility: hidden; */
}
.toggle-left-wrapper.--is-active-right {
  /* transform: translate(-170px); */
  /* visibility: hidden; */
}

.controls-header .tab-item {
  text-align: center;
  justify-content: center;
  width: 100%;
  align-items: center;
  /* opacity: 0.5; */
  cursor: pointer;
  padding: 15px;
  font-size: 0.9rem;
}

.controls-header .tab-item:hover {
  opacity: 1;
  background-color: #f8f9fa;
}

.controls-header .--is-active {
  opacity: 1;
  background-color: #f8f9fa;
}

.main-header .op-icon {
  color: rgb(0, 0, 0);
}
.filter-box-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 10px 0;
  /* overflow-x: scroll; */
  border-bottom: 1px solid rgb(240, 234, 234);
}

.filter-box-container:last-child {
  border-bottom: 0px;
}

.filter-box {
  padding: 8px 10px;
  background-color: rgba(233, 229, 229, 0.5);
  color: black;
  cursor: pointer;
  font-size: 0.8rem;
  margin: 2px 5px;
}
.filter-box:hover {
  background-color: #252525;
  color: white;
}

.filter-box.--is-active {
  background-color: #252525;
  color: white;
}

.controls-container {
  background-color: #ffffff;
  border-radius: 4px;
  -webkit-font-smoothing: subpixel-antialiased;
  border: 1px solid #dee5e8;
  box-shadow: 0 0 5px rgb(0 0 0 / 15%);
}

.control {
  position: relative;
  color: #212529;
  padding: 8px 12px;
  /* opacity: 0.5; */
  cursor: pointer;
}

.moodboard-controls-container .control:hover {
  opacity: 1;
}

.controls-tab-content {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  /* padding: 25px 0; */
  /* overflow-y: auto; */
  /* padding: 10px 10px; */
  flex-grow: 1;
  background: #fff;
  font-size: 0.9rem;
  height: 100%;
}

#productModulesContainer,
#spacePlannerContainer {
  display: none;
}

#productModulesContainer.--is-active,
#spacePlannerContainer.--is-active {
  display: block;
}

.customize-finish-controls-wrapper .dropdown-container {
  width: 100% !important;
  min-width: unset;
}

.filter-box:not(:first-child) {
  margin-left: 5px;
}

.tab-content-wrapper {
  height: -webkit-fill-available;
}

.tab-content {
  /* height: calc(100% - var(--tab-container-height)); */
  padding: 0;
  display: none;
  position: relative;
  flex: 1;
  height: 100%;
}

.product-description-wrapper {
  position: absolute;
  left: 100%;
  top: 50%;
  transform: translate(10px, -50%);
  display: none;
}

#productDescription.--is-active {
  display: block;
}

#totalPrice {
  transition: 0.5s ease-in;
}

#totalPrice.--is-active {
  transform: scale(1.2);
}

.product-description-wrapper .image {
  width: 350px;
  /* aspect-ratio: 4 / 3; */
}

.tab-content.--is-active {
  display: flex;
  flex-direction: column;
}

.controls-tab-container {
  position: relative;
  left: 0;
  top: 0;
  background: transparent;
  width: 100%;
  display: flex;
  /* padding: 5px 0; */
  /* height: var( --tab-container-height); */
}

.controls-tab-container > div {
  width: 110px;
  text-align: center;
  opacity: 0.5;
  padding: 6px 0;
  /* margin: 0 2px; */
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  min-width: 30px;
}

.controls-tab-container.--is-potrait-mode > div{
  padding: 12px;
}

.controls-tab .text {
  font-size: 12px;
  padding: 4px 0;
  pointer-events: none;
  color: #000;
  letter-spacing: 0.2px !important;
  text-align: center;
}

.controls-tab.--is-active {
  opacity: 1 !important;
  /* background-color: rgba(242, 242, 242, 0.6) */
}

.controls-tab.--is-active .text,.controls-tab.--is-active .icon{
  font-size: 14px;
  font-weight: bold;
  color: var(--krab-color-primary);
  color: "#000"
}

.controls-tab-red.--is-active .text,.controls-tab-red.--is-active .icon{
  font-weight: 800 !important;
  color: var(--krab-color-danger);
}

.controls-tab:hover {
  opacity: 1;
  /* background-color: rgba(239, 237, 237, 0.8); */
}

.controls-tab-active-bar{
  background: var(--krab-color-light);
  width: 100%;
  height: 2px;
  position: relative;
}

.controls-bar-fill{
  height: 3px;
  background: var(--krab-color-primary) !important;
  transition:0.5s all;
  margin-top: -1.5px;
}


.material-color-option-container {
  display: flex;
  align-items: flex-start;
  justify-content: start;
  padding: 0.5rem;
}

/* .search-container input[type=text] {
    padding: 6px;
    font-size: 0.8rem;
    border: none;
    background: #FFF;
    font-family: inherit;
    height: 30px;
    width: 100%;

  } */

.collection-dropdown-container {
  margin: 5px 0;
  font-size: 0.8rem;
  width: 100%;
  /* height: 15%; */
}
.collection-dropdown-container .drop-down {
  font-size: 0.7rem;
}

.texture-slider-wrapper {
  /* height: 75%; */
  width: 100%;
  overflow-y: auto;
}

.texture-slide {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  /* height:100%; */
  width: 100%;
  background: transparent;
  overflow-y: auto;
  overflow-x: hidden;
  align-content: baseline;
  /* height: 350px; */
}

#textureSearch:focus {
  outline: none;
}

.texture-label-container {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  padding: 5px;
  width: 100%;
  align-self: flex-start;
  position: relative;
  left: 0;
  margin: 6px 0;
  transition: 0.3s ease-in;
}

/* .texture-label-container:hover {
    transform: scale(1.04);

} */

.texture-label-container.--is-active {
  border: 1px solid rgba(187, 185, 185, 0.6);
}

.texture {
  position: relative;
  display: flex;
  width: 30%;
  align-items: center;
  pointer-events: none;
  min-height: 7rem;
}

.color-swatch-container {
  position: relative;
  padding: 3px;
}
.color-swatch {
  cursor: pointer;
  /* padding: 5px; */
  width: 100%;
  align-self: auto;
  position: relative;
  /* height: 100%; */
  /* left: 0; */
  aspect-ratio: 1;
  transition: 0.5s ease-in;
}

.texture-details {
  padding: 10px;
  width: 70%;
  /* text-align: center; */
  word-break: break-all;
  pointer-events: none;
}

.texture-details .detail {
  padding: 2px;
  font-size: 0.8rem;
}

/* .texture:hover {
    border: 2px solid #ffffff;
} */

.texture.--is-active {
  border: 2px solid #ffffff;
}

.floor-option {
  width: 50px;
  height: 50px;
  margin: 5px;
  cursor: pointer;
}
.materials-close-container {
  box-shadow: 1px 1px 5px #f0f0f0;
  border: 1px solid rgb(240, 234, 234);
  background-color: rgb(207, 224, 235);
}

.material-close-button {
  float: right;
}

.material-close-text {
  display: inline;
}

.product-description-toggle.--is-active {
  color: #000;
}

.checkmark {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: #f1f1ed;
  border-radius: 50%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  transition: all 2s;
  background-size: auto;
  background-repeat: no-repeat;
  background-position: center;
}

.canvas-loader {
  position: absolute;
  z-index: 40;
  width: 100%;
  height: inherit;
  background-color: rgba(255, 255, 255, 0.95);
  /* margin-left: -5px; */
}

.canvas-loader-icon {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.welcome-message-container {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}
.welcome-message-container .message {
  padding: 20px 0;
  font-size: 1.2rem;
  text-transform: uppercase;
  line-height: 20px;
}

.home-option,
.hint-text,
.saved-config,
.design {
  position: relative;
  margin-bottom: 1.875em;
  cursor: pointer;
  width: 75%;
  /* aspect-ratio: 16/7; */
  text-align: center;
  padding: 15px 0;
  cursor: pointer;
  border-radius: 5px;
}
.home-option img {
  width: 100%;
  height: 100%;
  filter: brightness(0.4);
  cursor: pointer;
}

.home-option .text {
  color: #000;
  /* font-size: 1.2rem; */
  margin-left: 10px;
}

.text-start-table td,
.text-start-table th {
  text-align: start;
  width: 50%;
}

.home-option:hover {
  transform: scale(1.08);
}

.product-material-dropdown-wrapper {
  height: auto;
}

.home-options {
  justify-content: start;
}

.hint-text-container {
  width: 100%;
}

.hint-text {
  padding: 20px;
}

.saved-config-container {
  justify-content: start;
  padding: 40px 0;
  height: 70%;
}

.saved-config {
  /* padding: 30px 10px; */
  font-size: 0.7rem;
  text-align: start;
  position: relative;
  display: flex;
  flex-direction: row;
  padding: 0;
}
.saved-config-text {
  margin-top: 10px;
}

.saved-config-areaviewfilter-container {
  padding: 0 0 5px 0;
  display: flex;
  flex-direction: row;
}

.saved-config-areaviewfilter-container > div {
  flex: 1;
}

.saved-config-areaviewfilter-container .dropdown {
  font-size: 0.9rem;
}

.saved-config-image-container {
  position: relative;
  width: 100%;
  height: calc(200px * 0.5655);
}

.saved-config-image-container img {
  width: 100%;
  height: 100%;
}

.saved-config-image {
  position: absolute;
  left: 0;
  top: 0;
}

.saved-config-details {
  position: absolute;
  color: black;
  padding: 5px 5px 0 5px;
  bottom: 0;
  width: 100%;
  text-align: center;
  background: rgba(238, 234, 234, 0.6);
}

.saved-config.--is-active {
  border: 2px solid green;
}
.saved-config:hover {
  background: #eeeded;
}

.design {
  padding: 56px 0;
  border-radius: 2px;
  background-color: rgba(233, 229, 229, 0.5);
  box-shadow: unset;
}

.action-container .text {
  color: #fff;
}

.action-container button {
  width: 100%;
}

.texture-name-mobile {
  display: none;
  height: 15px;
  overflow: hidden;
  background: rgba(255, 255, 255, 0.5);
  width: 95%;
  text-align: center;
  color: #000;
  font-weight: bold;
  font-size: 0.9rem;
  bottom: 1rem !important;
  padding: 0 0;
  margin: auto;
}

.customize-left-wrapper .dropdown-menu,
.customize-controls-wrapper .dropdown-menu {
  width: 100%;
}

.controls-tabs-mobile.--is-potrait-mode{
  display: flex;
}

@media screen and (max-height: 360px) {
  .home-button-mobile {
    visibility: visible;
  }
}

@media screen and (max-width: 480px) {

  .customizein3d-controls-tab-container{
    width: 0;
    height: 0;
    display: none;
    overflow-x: hidden;
  }
  .action-tabs-header{
    display: none;
  }
  .texture-label-container {
    width: 20%;
  }

  .texture-label-container .texture-details {
    display: none;
  }
  .texture-label-container .texture {
    width: 100%;
  }
  /* .texture {
        width: 100%;
    }
    .texture-details{
        display: none;
    } */
  .texture-name-mobile {
    display: block;
  }
  .toggle-left-wrapper.--is-active {
    transform: translate(-260px);
  }
  .controls-tab-content .search-container {
    width: 100%;
  }
}

@media screen and (min-width: 481px) and (max-width: 1024px) {
  .texture-label-container {
    width: calc(100% / 3);
    padding: 3px 3px;
    border-radius: 5px;
    box-shadow: none !important;
    margin: 0;
  }
  .texture {
    width: 100%;
  }
  .texture-details {
    display: none;
  }
  .texture-name-mobile {
    display: block;
  }
  .controls-tab-content .search-container {
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
  }
  .home-option,
  .hint-text,
  .saved-config,
  .design {
    margin-bottom: 1em;
    padding: 15px 10px;
  }

  .footer-item {
    font-size: 0.7rem;
    padding: 8px;
  }

  .texture-details {
    padding: 5px;
    width: 75%;
    font-size: 0.6rem;
  }

  .collection-dropdown-container {
    margin: 4px 0;
  }

  .collection-dropdown-container .drop-down {
    font-size: 0.6rem;
  }
  .welcome-message-container {
    margin-bottom: 5px;
  }
  .welcome-message-container .message {
    font-size: 0.9rem;
    padding: 0;
  }
  .welcome-message-container .message .ion-padding-top {
    padding-top: 10px;
  }
  .controls-tab-container > div {
    padding: 2px;
    align-items: center;
    justify-content: center;
    display: flex;
  }
  .product-description-wrapper .image {
    width: 150px;
  }
}

@media screen and (min-width: 480px) {
  .customizein3d-controls-tab-container {
    position: fixed;
    /* width: 120px; */
    flex-direction: column;
    height: calc(100% - var(--main-header-height));
    background: white;
    background-color: white;
    right: 0;
    bottom: 0;
    top: unset;
    left: unset;
    z-index: 100;
    width: 0;
    padding: 0;
    overflow-x: hidden;
  }

  .controls-tabs-mobile{
    display: none;
  }

  .customizein3d-controls-tab-container > div {
    flex: unset;
    padding: 15px 5px;
  }
  .customizein3d-controls-tab-container .controls-tab {
    opacity: 1 !important;
    width: 100%;
    padding: 8px 0;
  }

  .customizein3d-controls-tab-container .controls-tab.--is-active {
    border-bottom: 1px solid var(--krab-color-light);
  }
}
