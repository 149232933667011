.query-container:nth-child(1) {
    margin-left: 0;
}

.query-container {
    width: 300px;
    height: fit-content;
    padding: 0.5rem;
}

.query-container .name-icon {
    width: 40px;
    height: 40px;
    border-radius: 10px;
    font-size: 18px;
    font-weight: bold;
    cursor: pointer;
}

.query-container:hover {
    transform: scale(1.01);
    background: #f9f9f9;
}

.query-container .top-content .name-details {
    flex: 1;
    margin-left: 10px;
    /* border: 2px solid rgb(26, 16, 8); */
}

.name-details .heading4 {
    text-wrap: nowrap;
    display: flex;
    align-items: center;
    text-overflow: ellipsis;
}

.query-container .bottom-content {
    justify-content: space-between;
}

.query-container .bottom-content .icon {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background: var(--krab-color-light);
    font-size: 18px;
}

.new-message {
    background-color: var(--krab-color-primary);
    color: #fff;
    font-weight: 500;
    font-size: 0.6rem;
    padding: 3px 10px;
}

.inclusions .inclusion {
    margin: 0 20px;
}


.inclusions .icon {
    border: 1px double;
    border-radius: 50%;
    padding: 10px;
    border-color: var(--krab-color-warning);
}


.service-list-filter .item {
    padding: 5px 7px;
    border: 1px solid var(--krab-color-dark);
    border-radius: 10px;
}

.service-list-filter .item .text {
    color: var(--krab-color-dark);
}

.service-list-filter .item.--is-active {
    background: var(--krab-color-primary);
}

.service-list-filter .item.--is-active .text {
    color: #fff;
}

.queryList100-container{
     height: 100%;
     display: flex;
     flex-direction: column;
     text-align: center;
     align-items: center;
     justify-content: center;
     gap: 20px;
     padding: 20px;
}

.WattsapLinkNumber-container{
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;  
    align-items: center;   
    gap: 15px;
}

.CancleQueryReason-container{
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;  
    align-items: center;   
    gap: 15px;
}

.queryList100-btn{
    padding: 8px;
    color: white;
    width: 30%;
    border-radius: 5px;
    font-weight: bold;
}

.WattsapLinkNumber-btn{
    padding: 8px;
    color: white;
    width: 53%;
    border-radius: 5px;
    font-size: medium;
    font-weight: 500;
}

.CancleQueryReason-btn{
    padding: 8px;
    color: white;
    width: 30%;
    border-radius: 5px;
    font-weight: 500;
}

.my-modal .modal-wrapper{
    --width: 100%;
    --height: 100%;
    --max-width: 400px;
    --max-height: 20%;
} 

.my-modal-wattsapp .modal-wrapper{
    --width: 100%;
    --height: 100%;
    --max-width: 400px;
    --max-height: 40%;
}

.my-modal-cancleQuery .modal-wrapper{
    --width: 100%;
    --height: 100%;
    --max-width: 300px;
    --max-height: 25%;
}

.requested-content {
    font-weight: 600;
    font-size: 12.5px !important;
}

.requested-right-content{
    text-align: end;
}


/* //--------my-css-query-------------// */



@media (max-width:1227px) {
    /* .query-container{
        background-color: rgb(209, 209, 216);
    } */

    .content-fit {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .headingg {
        font-weight: bold;
        font-size: 10px !important;
        margin: 0.2vw;
    }

    .addmargin {
        /* border: 1px solid blue; */
        margin-left: 2px !important;
    }

    .margin1 {
        margin-top: 5px;
    }

    .margin1 .fitsize {
        /* font-weight: bold; */
        font-size: 12px;
        /* font-size: 20px !important; */
    }

}

@media (max-width:895px) {
    .margin1 .fitsize {
        margin: 2px;
        /* font-weight: bold ; */
        font-size: 11px !important;
        /* border: 2px solid red */
    }
}

@media (max-width:690px) {
    .headingg {

        display: none !important;
    }

    .flex1 {
        margin-left: 2px !important;
    }

    .flex {
        /* border: 1px solid red; */
        display: flex;
        align-items: center;
        flex-wrap: nowrap;
        gap: 5px;
        padding: 0px !important;
        margin: 0px !important;
        /* margin-bottom: -5px !important; */
        /* margin-top: 0px !important; */
    }

    .flex-clm {
        flex-direction: column !important;
        width: 70% !important;
        /* margin: 0px !important; */
    }

    .marginnn {
        margin-top: 2px !important;
    }

    .margin1 {
        margin-top: 0px !important;
    }

    .flex-mobile {
        display: flex;
        justify-content: space-between;
        gap: 5px;
    }

    .marginRight {
        margin-right: 55px !important;
    }

    .marginRight1 {
        /* border: 3px solid blue; */
        margin-right: 62px !important;
    }

     
}

@media (max-width:570px){
    .visa-details-ft-size{
        font-size: 10.5px !important;
        font-weight: 700 !important;
    }
}

@media (max-width:480px){
    
}

@media (max-width:457px) {

    .header2-content-container{
       gap: 10px !important;
    }

    .header2-filed-container {
        margin: 0px !important;
    }

    .header2-content {
        font-size: smaller !important;
        margin-left: 5px !important;
    }
}

@media (max-width:522px) {
    .flex-clm {
        flex-direction: column !important;
        width: 100% !important;
        /* margin: 0px !important; */
    }
}

@media (max-width:494px){
    .requested-content {
        font-weight: 700;
        font-size: 11px !important;
    }
}

@media (max-width:459px) {
    .query-main-cont {
        padding: 0px !important;
    }
    .requested-content {
        font-weight: 700;
        font-size: 10.5px !important;
    }
}

@media (max-width:437px){
    .requested-right-content{
        margin-left: 10px !important;
    }
}

@media (max-width:425px) {
    .requested-content {
        font-weight: 700;
        font-size: 10px !important;
    }
    .requested-right-content{
        margin-left: 5px !important;
    }
    .query-main-cont {
        padding: 0px !important;
    }

    .header-query-content {
        /* border: 2px solid red; */
        font-size: medium !important;
    }

    .header-query-status {
        /* border: 3px solid blue !important; */
        padding: 5px !important;
        font-size: smaller !important;
    }

    .header-cancle-btn {
        font-size: medium !important;
    }

    .options-content {
        /* border: 2px solid red; */
        font-size: 11.5px;
        padding: 5px !important;
        position: absolute !important;
        top: 20px !important;
        font-weight: 400;
    }

    .options-img {
        /* border: 2px solid red; */
        width: 200px;
    }

    .options-container {
        /* border: 3px solid blue; */
        padding-bottom: 0px !important;
    }

    
}

@media (max-width:440px){
    .header2-content {
        font-size: smaller !important;
        margin-left: 2px !important;
    }
}

@media (max-width:400px){
    .requested-content {
        font-weight: 700;
        font-size: 9.5px !important;
    }
    .requested-right-content{
        margin-left: 5px !important;
    }
}

@media (max-width:407px) {
    .query-img {
        width: 30px;
        height: 50px;
        border: none !important;
    }

    .visa-img {
        width: 30px;
        height: 50px;
    }

    .heading-4 {
        font-size: 13px;
    }

    .query-main-cont {
        padding: 2px !important;
    }

    .margin1 {
        margin-left: -2px !important;
    }

    .query-main-cont {
        padding: 0px !important;

    }
    
    .visa-date-picker .center-aligned-row{
        border: 2px solid blue;
    }
   
}

@media (max-width:396px){
    .header2-content {
        font-size: 9px !important;
        font-weight: 700;
        margin-left: 2px !important;
    }
    .requested-content {
        font-weight: 700;
        font-size: 9px !important;
    }
    .header2-heading{
        font-size: large !important;
    }
}

@media (max-width:380px) {
    .visa-details-ft-size{
        font-size: 9.5px !important;
        font-weight: 700 !important;
    }
 
    .query-main-cont {
        /* border: 2px solid red; */
        padding: 0px !important;
    }

    .heading-4 {
        font-size: 12px;
    }

    .query-img {
        width: 25px;
        height: 45px;
        border: none !important;
    }

    .visa-img {
        width: 25px;
        height: 45px;
    }

    .margin1 {
        margin-left: -5px !important;
    }

    .requested-content {
        font-weight: 700;
        font-size: 9px !important;
    }
    .requested-right-content{
        margin-left: 5px !important;
    }

    .my-modal .modal-wrapper{
        --width: 100%;
        --height: 100%;
        --max-width: 340px;
        --max-height: 20%;
    } 
    
    .my-modal-wattsapp .modal-wrapper{
        --width: 100%;
        --height: 100%;
        --max-width: 350px;
        --max-height: 40%;
    }

    

}

@media (max-width:367px) {
    .marginRight {
        margin-right: 51px !important;
    }

    .marginRight1 {
        margin-right: 59px !important;
    }

    .margin1 .fitsize {
        font-size: 10.5px !important;
    }
    .font-cont {
        margin-left: 3px !important;
    }

    .margin-acc{
        margin-right: 0px !important;
    }

    .font-size1 {
        margin-left: 1px !important;
    }

    .font-size {
        margin-left: 5px !important;
    }

    .margin-left {
        margin-left: 30px !important;
    }

    .requested-content {
        font-weight: 700;
        font-size: 8.7px !important;
    }
    .requested-right-content{
        margin-left: 5px !important;
    }

}

/* //--------my-css-query-------------// */

@media (max-width:480px) {
    .query-container {
        margin-left: 0px;
        width: 100%;
    }

    .leads-wrapper {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }


}