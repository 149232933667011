.home-bg-gradient{
    width: 100%;
    height: 450px;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 20px 120px;
}

.home-page-wrapper{
    background: rgba(249, 247, 247, 0.8);
}

.home-page-wrapper .header{
    padding: 20px 20px;
}

.landing-banner .header{
}

.home-page-options .option .icon{
    font-size: 2rem;
   
}

.home-page-options .option .text{
    font-size: 2rem;
   
}

@media (max-width:480px){
    .home-page-options .option{
        width: 80%;
        height: auto;
        padding: 10px 20px;
    }

    .home-page-options .img{
        filter: brightness(0.5);
        width: 100%;
        z-index: 1;
    }

    .home-page-options .text{
        z-index: 2;
        letter-spacing: 2px;
    }

}