.gap5{
    gap: 5px;
}

.gap10{
    gap: 10px;
}

.align-items{
    align-items: center
}

.adult-cont{
    /* border: 2px solid black; */
    justify-content: space-evenly;
    gap: 5px;
}

.adult-cont-content{
    width: 40%;
    /* border: 1px solid red; */
    /* gap: 15px; */
    justify-content: space-between;
    align-items: center;
}

.adult-cont-content > .input-container{
    width: 70%;
}

.child-cont{
    /* border: 2px solid red; */
    justify-content: space-evenly;
    gap: 5px;
}

.child-cont-content{
    width: 40%;
    /* border: 1px solid red; */
    /* gap: 15px; */
    justify-content: space-between;
    align-items: center;
}

.child-cont-content > .input-container{
    width: 70%;
}
