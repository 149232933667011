:root{
    --krab-button-bg-color:#e0e0e0;
    --krab-color-primary:#1f5ad6;
    --krab-color-success:rgb(0, 171, 85);
    --krab-color-danger:rgb(183, 33, 54);;
    /* --krab-color-danger:#d92550; */
    --krab-color-secondary:#dc5b0a;
    --krab-color-light:#F5F5F5;
    --krab-color-ex-light:#f8f7f7;
    --krab-color-info:#06b5dd;
    --krab-color-ternary:#f0f0f8;
    --krab-color-dark:#707070;
    --krab-color-active:#f1f5fa;
    --krab-color-warning:#ffbf43;

    --krab-color-ex-dark:#333233;


    --krab-font-size:13.5px;

    --main-sidebar-width:8rem;
    --filter-header-height:auto;
    --main-header-height:5rem;
    --featured-container-wrapper-height:200px;
}


@media (max-width:480px){

    :root{
        --main-sidebar-width:0px;
    }

}


@media screen and (min-width:481px) and (max-width:1024px){
    :root {
        --tab-container-height:45px;
        --filter-header-height:auto;

        --op-font-size:10px;


    }


}