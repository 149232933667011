.component-loader{
  visibility: hidden;
  background: rgb(252, 252, 252);
  /* background: rgba(255,255,255,0.5); */
  z-index: 9999;
  transition: none;
}

.component-loader.--is-active{
  /* position: fixed; */
  visibility: visible;

}
.progress-loader-container{
  padding: 45px 65px;
  background: rgba(255,255,255,1);
  /* background: rgba(255,255,255,0.5); */

}

.progress-compact-loader{
  padding: 10px 25px;
}
.progress-compact-loader .progress-bar-container{
  width: 200px;
}
.progress-loader-container>div{
  padding: 18px 0;
}




.fade-background-loader{
  background: rgba(255,255,255,0.8) !important;
}

.progress-bar-container {
    width: 250px;
    background-color: #f1f1f1;
    padding: 0 !important;
    border-radius: 10px;
    overflow: hidden;
  }
  
  .progress-bar {
    width: 0;
    background-color: var(--op-color-primary);
    color: white;
    /* transition: 0.05s ease-in; */
    transition: width 0.5s;
    height: 12px;
    border-radius: 10px;
    box-shadow: 0 0 5px var(--op-color-primary),
    0 0 5px var(--op-color-primary);
  }

  .progress-bar-top::after{
    content: "";
    position: absolute;
    right: -5px;
    top: -1px;
    width: 0;
    height: 0;
    border-radius: 50%;
    background: var(--op-color-primary);
    box-shadow: 0 0 5px var(--op-color-primary),
                0 0 5px var(--op-color-primary);
  }


  .custom-skeleton-text{
    --background:rgba(var(--background-rgb, var(--ion-text-color-rgb, 0, 0, 0)), 0.065);
    padding: 0;
    margin: 2px 0;
  }

  .dots-loader-container{
    padding: 1.5rem 6.5em;
  }

  
/**
 * ==============================================
 * Dot Flashing
 * ==============================================
 */
 .dot-flashing {
  position: relative;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: var(--op-color-primary);
  color: var(--op-color-primary);
  animation: dotFlashing 1s infinite linear alternate;
  animation-delay: .5s;
}

.dot-flashing::before, .dot-flashing::after {
  content: '';
  display: inline-block;
  position: absolute;
  top: 0;
}

.dot-flashing::before {
  left: -15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: var(--op-color-primary);
  color: var(--op-color-primary);
  animation: dotFlashing 1s infinite alternate;
  animation-delay: 0s;
}

.dot-flashing::after {
  left: 15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: var(--op-color-primary);
  color: var(--op-color-primary);
  animation: dotFlashing 1s infinite alternate;
  animation-delay: 1s;
}

@keyframes dotFlashing {
  0% {
    background-color: var(--op-color-primary);
  }
  50%,
  100% {
    background-color: #ebe6ff;
  }
}

  @media screen and (max-width:480px){
    .progress-bar-container {
      width: 150px;
    }

    .progress-compact-loader .progress-bar-container{
      width: 140px;
    }
    .progress-loader-container>div{
      padding: 10px 0;
    }
}

@media screen and (min-width:481px) and (max-width:1024px){
   
  .progress-bar-container {
    width: 180px;
  }
  
  .progress-compact-loader .progress-bar-container{
    width: 140px;
  }

  .progress-loader-container>div{
    padding: 10px 0;
  }

}