.main-side-bar .header{
    height: var(--main-header-height);
    align-items: flex-start;
    margin-top: 0.5rem;
}

.main-side-bar .header .brand-name{
    font-size: 0.9rem;
    font-weight: 500;
    color: #fff;
}

/* .main-side-bar .navigation-links .nav-link-container{
    opacity: 1;
} */

.main-side-bar .navigation-links .nav-link-container.--is-active{
    border-radius: 10px;
    background-color: var(--krab-color-light);
}

.main-side-bar .navigation-links .link{
    color: #fff;
}

.main-side-bar .navigation-links .icon{
    color: #fff;
}

.nav-link-container.--is-active .link{
    color: #000;
}

.nav-link-container.--is-active .icon{
    color: #000;
}