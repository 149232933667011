 

.op-btn {
    display: inline-block;
    font-weight: 400;
    color: #495057;
    text-align: center;
    vertical-align: middle;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: .375rem .75rem;
    line-height: 1.5;
    border-radius: .25rem;
    font-size: 0.85rem;
    transition: color 0.15s,background-color 0.15s,border-color 0.15s,box-shadow 0.15s;
  }

  
.ripple-effect {
    position: absolute;
    border-radius: 50%;
    background-color: rgba(188, 183, 183, 0.7);
    animation: ripple 0.5s linear;
}

.ripple-effect.color-dark{
    background-color: var(--krab-color-dark);
}

.ripple-effect.color-primary{
    background-color: rgba(227, 227, 227, 0.7);
}


  
  @keyframes ripple {
    from {
      transform: scale(0);
      opacity: 1;
    }
    to {
      transform: scale(2);
      opacity: 0;
    }
  }
  
  .clicked {
    outline: none;
    transform: scale(0.95);
    transition: box-shadow 0.05s linear;
    box-shadow: 3px 2px 22px 1px rgba(0, 0, 0, 0.24);
  }
  
 
  .tap-button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: rgba(148, 147, 147, 0.5);
    opacity: 0;
    transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
    animation: tap-button 0.5s;
    transform-origin: center;
  }
  
  @keyframes tap-button {
    from {
      transform: translate(-50%, -50%) scale(0);
      opacity: 0;
    }
    to {
      transform: translate(-50%, -50%) scale(1);
      opacity: 1;
    }
  }
  

  .op-btn > i , .op-btn > span {
      pointer-events: none;
  }



  .op-btn:focus,.op-btn:active{
    outline: none;

  }
  
  .op-btn-primary:hover{
    color: #fff;
    background-color: var(--krab-color-primary);
    border-color: var(--krab-color-primary);
    /* background-color: #2955c8;
    border-color: #2651be; */
}
  .op-btn-primary {
    color: #fff;
    background-color: var(--krab-color-primary);
    border-color: var(--krab-color-primary);
}

.op-btn-primary .text{
    color: white;
}
.op-btn-primary-inverse,.op-btn-primary-inverse>i {
    color: #000;
    border-color: var(--krab-color-primary) !important;
    background-color: transparent;
}

.op-btn-primary-inverse:hover,.op-btn-primary-inverse.--is-active,.op-btn-primary-inverse:hover>i {
    color: var(--krab-color-primary);
    /* font-weight: 500; */
    /* background-color: transparent; */
    background-color: var(--krab-color-light) !important;
    border-color: var(--ion-color-light-shade);
}

.op-btn-warning {
    color: #fff;
    background-color: var(--krab-color-warning);
    border-color: var(--krab-color-warning);
}

.op-btn-info {
    color: #fff;
    background-color: var(--krab-color-info);
    border-color: var(--krab-color-info);
}

.op-btn-light-outline {
    color: #000;
    background-color: transparent;
    border-color: var(--krab-color-light);
}

.op-btn-light-outline:hover{
    color: #000;
    background-color: var(--krab-color-light);
    border-color: var(--krab-color-light);
}

.op-btn-success {
    color: #fff;
    background-color: var(--krab-color-success);
    border-color: var(--krab-color-success);
}

.op-btn-danger {
    color: #fff;
    background-color: var(--krab-color-danger);
    border-color: var(--krab-color-danger);
}

.op-btn-light {
    color: #000;
    background-color: var(--krab-color-light);
    border-color: #dbdbdb;
}

.op-btn-light:hover {
    color: #212529;
    background-color: #dbdbdb;
    border-color: #d5d5d5;
}

.op-btn:active {
    transform: scale(0.95);
    box-shadow: 3px 2px 22px 1px rgba(0, 0, 0, 0.24);
}

.op-btn-lg{
    padding: 10px 20px !important;
    font-size: 1rem;
}

.op-btn-lg .text{
    font-size: 1.2rem;
    font-weight: 500;
}

.op-btn-primary-inverse-active{
    color: var(--krab-color-primary);
    border: 1px solid var(--krab-color-primary);
}

.button , .button2{
    cursor: pointer;
    margin: 0 5px;
    width: max-content;
    height: fit-content;
    /* border: 1px solid rgba(233, 229, 229, 1); */
}

.button2{
    border: none;
    padding: 0.7rem 0.9rem;
    /* border: 1px solid rgba(233, 229, 229, 1); */
    background: #fff;
}


.header-share-button.button2{
    padding: 0.5rem;
}
.header-share-button.button2 .text{
    font-weight: 500;
}
.button2.heading1 .text,.button2.heading1 .icon{
    font-weight: 800;
}
.button-primary{
    background-color: var(--krab-color-primary);
}

.button-primary .text,.button-primary .icon{
    color: rgb(255, 255, 255);
}


.button-light{
    background-color: var(--krab-color-light);
}

.button-light .text,.button-light .icon{
    color: rgb(0, 0, 0);
}

.button-warning{
    background-color: var(--krab-color-warning);
}

.button-warning .text,.button-warning .icon{
    color: rgb(255, 255, 255);
}



.button .text,.button2 .text{
    font-size: 0.9rem;
    text-transform: capitalize;
    /* text-transform: uppercase; */
}

.button2 .text{
    letter-spacing: 0.05rem;
    font-size: 0.9rem;
    font-weight: 400;
    margin: 0 5px !important;
    white-space: nowrap;
}

.button2.text-center .text{
    width: 100%;
    text-align: center;
}

.button2.font-medium .text{
    font-size: 0.95rem;
}

.button2.font-large .text{
    font-size: 1.1rem;
}

.button2.bg-color-primary .text,.button2.bg-color-primary .icon,.button2.bg-color-success .text{
    color: #fff !important;
}

.button2.bg-color-warning .text,.button2.bg-color-warning .icon{
    color: #fff !important;
}

.button2.bg-color-warning-outline{
    border-color: var(--krab-color-warning) !important;
}

.button.bg-color-primary .text,.button.bg-color-primary .icon{
    color: #fff !important;
}

.button2.bg-color-black .text,.button2.bg-color-black .icon{
    color: #fff !important;
}

.button2.bg-color-primary:active{
    background-color: #212529;
}

.button2.bg-color-danger-outline{
    border-color: var(--krab-color-danger);
}


.button2.bg-color-danger-outline .text{
    color: var(--krab-color-danger);
}

.button2.bg-color-primary-outline{
    border-color: var(--krab-color-primary);
}


.button2.bg-color-primary-outline .text{
    color: var(--krab-color-primary);
}

/* .button2.bg-color-warning .text,.button2.bg-color-warning .icon{
    color: #fff !important;
} */


.button-large{
    padding: 1rem 1.8rem;
}

.button-large .text{
    font-size: 1.1rem;
}

.button-large .icon{
    font-size: 1rem;
}

.button-medium{
    padding: 0.9rem 1.5rem;
}

.button-medium .text{
    font-size: 1rem;
}

.button-medium .icon{
    font-size: 0.9rem;
}

.button-rounded{
    border-radius: 25px !important;
}


.button-small{
    padding: 0.5rem 1rem !important;
}

.button-small .text{
    font-size: 0.9rem;
    font-weight: 400;
}

.button-small .icon{
    font-size: 0.9rem;
}

.button-ex-small{
    padding: 0.3rem 0.5rem !important;
}

.button-ex-small .text{
    font-size: 0.8rem;
    font-weight: 400;
}

.button-ex-small .icon{
    font-size: 0.8rem;
}

.button-md-small{
    padding: 0.5rem 0.7rem !important;
}

.button-md-small .text{
    font-size: 0.8rem;
}

.button-md-small .icon{
    font-size: 0.8rem;
}

.button2.--is-active .state-text{
    color: var(--krab-color-danger);
    font-weight: 600;
}


.button2.--is-active{
    /* background-color: var(--krab-color-primary) !important; */
    border-color: var(--krab-color-primary) !important;
    /* background: rgba(255,255,255,0.5) !important; */
}

/* .button2.--is-active .icon{
    color: #fff !important;
} */

.toast-button.button2{
    padding: 7px 15px;
}

.close-menu{
    padding: 10px;
}

.toast-button.button2 .text{
    font-weight: 400;
    margin: 0 !important;
    padding: 0 7px;
    font-size: 0.8rem;
}

.toast-button.button2 .icon{
    font-size: 0.8rem;
}


.redo-icon{
    transform: rotateY(180deg);
}

.button-fixed-width{
    width: 12rem !important;
}

/* .button .icon{
   color: var(--krab-color-primary);
} */
/* 
.button:hover{
    background-color: var(--krab-color-primary);
}

.button:hover .text{
    color: white;
}

.button:hover .icon{
    color: white;
} */

.button-border-blue{
    border: 1px solid blue;
}


@media screen and (max-width:480px){
    .button-large{
        padding: 0.8rem 1.3rem;
    }
    .button-large .text{
        font-size: 1rem;
        text-align: center;
    }
  
    .button-md-small{
        padding: 0.5rem 0.5rem !important;
    }
    .button-fixed-width{
        width: 90px !important;
    }
    .hide-button-text-in-mobile .text{
        display: none;
    }
}


@media screen and (max-width:360px){
    .button-fixed-width{
        width: 70px !important;
    }
}