 
.create-card-form-container>div:nth-child(1){
    width: 70%;
}

 
.create-card-form-container>div:nth-child(2){
    width: 30%;
}

select{
    width: 100%;
    border-color: #ddd;
    border-width: 1px;
   padding: 8px 14px;
  }

  select:focus{
      outline: none;
  }

  input:focus{
      outline: none;
  }

  .select-items-container{
      display: flex;
      overflow-y:auto;
      height: 100%;
    

  }
  .input{
    display: block;
    width: 100%;
    height: calc(2.25rem + 2px);
    padding: .375rem .75rem;
    font-weight: 300;
    font-size: 0.9rem;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    transition: border-color 0.15s ease-in-out,box-shadow 0.15s ease-in-out;
}

.input-range-number{
    border-radius: 0;
    border-top: none;
    border-bottom: none; 
    padding: 0;
    text-align: center;
    font-weight: 800;
    font-size: 1rem;
    user-select: none;
    pointer-events: none;
    height: calc(1.8rem + 2px);
}


.search-input {
    height: 35px;
    width: 100%;
    font-weight: 500 !important;
    font-size: 15px;
    /* padding-left: 30px; */
    border-radius: 5px;
    color: var(--krab-color-dark);
    border: 1px solid #ced4da;
      background-color: #fbfbfb;
  }

  .input-error{
    border-color: var(--krab-color-danger);
  }

  .select-items-container>div{
      padding: 10px;
      width: calc(100%/3);
      aspect-ratio: 4/3;
      display: flex;
      align-items: center;
      justify-content: center;
      background: rgba(0,0,0,0.5);
      cursor: pointer;
      border: 1px solid rgb(255, 249, 249);
  }

  .select-items-container>div:hover{
      background: rgba(0,0,0,0.9);
  }

  .select-items-container .text{
      color: white;
      text-transform: uppercase;
  }


  .select-options-wrapper .select-option{
    margin: 15px 30px;
    margin-left: 0;
  }

  
  .select-options-wrapper .icon-wrapper:hover{
   border: 1px solid var(--op-color-primary);
  }

  .select-options-wrapper .icon-wrapper:hover .icon{
    color: var(--op-color-primary);
   }
  .select-options-wrapper .icon-wrapper{
        cursor: pointer;
        width: 150px;
        aspect-ratio: 1/1;
        border-radius: 10px;
        background: var(--op-color-light);

  }

  .select-options-wrapper .icon-wrapper .icon{
      font-size: 5.5rem;
      color: rgba(153, 150, 150,0.6);
  }
  .select-options-wrapper .title{
      font-weight: bold;
      text-align: center;
      margin-top: 10px;
}

.added-materials-wrapper table tr td{
    border: none !important;
}


.full-width-file-input-container{
    width: 100%;
    height: auto;
    border: 3px dashed var(--op-color-light);
    border-radius: 25px;
    padding: 3rem 0;
}

.full-width-file-input-container .image-icon{
    font-size: 5rem;
}

.box-select-container .box-select{
    width: 120px;
    height: 100px;
    border: 1px solid var(--op-color-light);
    color: var(--op-color-dark);
    border-radius: 5px;
    letter-spacing: 0.1rem;
    cursor: pointer;
}

.box-select-container .box-select:hover{
   border-color: var(--op-color-primary);
   color: #000;
   /* background-color: var(--op-color-primary);
   color: white; */
}

.rembg-resolution-container .op-btn{
    padding: 20px 30px !important;
    background: #fff;
}

.rembg-resolution-container .op-btn>div{
    pointer-events: none;
}

.rembg-image-options .op-btn-primary-inverse:hover>i{
    background-color: transparent !important;
}

.log-timestamp-options{
    position: relative;
    margin-right: 10px;
}

.log-timestamp-options .container{
    border: 1px solid rgba(73, 71, 71, 0.9);
}
.log-timestamp-options .option{
    padding: 6px 20px;
    color: rgba(0,0,0,1);
    font-size: 0.85rem;
    cursor: pointer;
}

.log-timestamp-options .option.--is-active{
    color: var(--op-color-primary);
}

.log-timestamp-options .custom-options-container{
    position: absolute;
    top: 101%;
    left: 0;
    background: #fff;
    padding: 40px 20px;
    width: auto;
    border: 1px solid rgba(239, 238, 238, 0.9);
    border-top: 0;
    display: none;
}

#customTimestampinput.--is-active{
    display: block;
}

.label-container{
    padding: 10px 0;
}

.input.mandatory{
    border-color: var(--krab-color-danger);
    position: relative;
}

.search-input.mandatory{
    border: 1px solid var(--krab-color-danger) ;
}

.required-text{
    content: "Required";
    position: absolute;
    top: -15px;
    right: 0;
    color: var(--krab-color-danger);
    z-index: 30;
    font-size: 0.7rem;
    font-weight: 500;
    display: none;
}

.required-text.--is-active{
    display: block;
}

.label-container .label{
    font-weight: 500;
}

.first.name,.last.name,.registration.name,.gst_contact_name.name,.billing.city,.billing_state.name{
    text-transform: capitalize;
}

.range-date-container{
    width: 100%;
}

@media (max-width:480px){
     
    .create-card-form-container{
        flex-direction: column-reverse;   
        flex-wrap: nowrap;
        height: auto;
    }

    .create-card-form-container .upload-image-input{
        width: 150px;
    }
    .create-card-form-container>div:nth-child(1){
        width: 100%;
    }
    .create-card-form-container>div:nth-child(2){
        width: 100%;
    }

    .create-card-form-container>div{
        width: 100%;
        height: auto;
    }

}

@media screen and (min-width:481px) and (max-width:1024px){
     
}

/* For calender */
@media (max-height: 450px), (max-width: 450px){
    .rmdp-day, .rmdp-week-day {
        width: 30px !important;
    }
}