.profile-user-image{
    width: 80px;
}

@media (max-width: 650px){
    
    .font-size{
        margin-right: 7px !important;
        font-size: 12px;
        font-weight: 600;
    }
}

@media (max-width: 584px){
    .main-card-white{
        padding: 12px !important;
    }
    .image{
        width: 60px;
        height: 70px;
    }
    .other{
        margin-left: 5px !important;
    }
    .img-size{
        padding-top: 5px ;
    }
    .headingggg{
        font-size: 16px;
    }
    .headingg1{
        font-size: 12px;
        margin-top: 3px !important;
    }
    .headingg2{
        font-size: 11px;
    }
    .logo-icon{
        height: 12px;
        width: 12px;
    }
    .main-cont{
        padding: 3px !important;
    }
    .font-size{
        margin-right: 5px !important;
        font-size: 10px;
        font-weight: 600;   
    }
    
}

@media (max-width: 400px){
    .main-cont{
        padding: 3px !important;
    }
    .font-size{
        margin-right: 4px !important;
        font-size: 8.5px;
        font-weight: 600;   
    }
}