.color-light {
  color: var(--krab-color-light) !important;
}

.color-info {
  color: var(--krab-color-info) !important;
}

.color-ex-dark {
  color: var(--krab-color-ex-dark) !important;
}

.color-dark {
  color: var(--krab-color-dark) !important;
}

.color-primary {
  color: var(--krab-color-primary) !important;
}

.color-warning{
  color: var(--krab-color-warning) !important;
}

.color-success {
  color: var(--krab-color-success) !important;
}
.color-danger {
  color: var(--krab-color-danger) !important;
}
.bg-color-white {
  background: #fff !important;
}
.bg-color-black {
  background-color: #000 !important;
}
.bg-color-light {
  background: var(--krab-color-light) !important;
}
.bg-color-ex-light {
  background: var(--krab-color-ex-light) !important;
}
.bg-color-primary {
  background: var(--krab-color-primary) !important;
}
.bg-color-ternary {
  background: var(--krab-color-ternary) !important;
}
.bg-color-active {
  background: var(--krab-color-active) !important;
}
.bg-color-info {
  background: var(--krab-color-info) !important;
}

.bg-color-primary-light {
  background: rgba(31, 90, 214, 0.2);
}

.bg-color-success-light {
  background: rgba(0, 171, 85, 0.2);
}

.bg-color-warning-light {
  background: rgba(255, 191, 67, 0.2)
}

.bg-color-danger-light {
  background: rgba(183, 33, 54,0.2)
}

.bg-color-dark-light {
  background: rgba(112, 112, 112,0.2);
}

.color-white {
  color: #fff !important;
}

.color-black {
  color: #000 !important;
}
.background-white {
  background: #fff !important;
}
.background-primary {
  background: var(--krab-color-primary);
}

.zindex30 {
  z-index: 30 !important;
}
.zindex1 {
  z-index: 1 !important;
}
.zindex100 {
  z-index: 100 !important;
}
/* .tooltip:hover .tooltiptext {
    visibility: visible;
  } */

.icon-text-container {
  display: flex;
}

.disable {
  pointer-events: none;
  cursor: auto;
  opacity: 0.5;
}

.center-aligned-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.center-aligned-column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.custom-card {
  background-color: #fff;
  background-clip: border-box;
  border-radius: 5px;
  border: 0 solid transparent;
  box-shadow: 0 0 20px rgb(0 0 0 / 8%);
}

.white-card {
  background-color: #fff;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  border-radius: 5px;
}

.barfiller {
  width: 100%;
  height: 8px;
  background: var(--krab-color-light);
  position: relative;
  border-radius: 30px;
}

.barfiller .tipWrap {
  display: inline;
}

.barfiller .tip {
  color: #508ff4;
  left: 43.2265px;
  transition: left 2s ease-in-out 0s;
}

.barfiller .fill {
  display: block;
  position: relative;
  width: 0px;
  height: 100%;
  z-index: 1;
  border-radius: 30px;
  background: rgb(255, 191, 67);
  width: 66.664px;
  transition: width 2s ease-in-out 0s;
}

.barfiller .barhint {
  position: absolute;
  bottom: 150%;
  right: 0;
  padding: 5px;
  color: var(--krab-color-dark);
  font-size: 0.6rem;
  font-weight: bold;
  /* background: var(--krab-color-primary); */
  border-radius: 5px;
  /* color: #fff; */
}

.barhint-details {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 25px 40px;
  z-index: 101;
  /* display: none; */
}

.barhint-details.--is-active {
  display: block;
}
.barhint-details .step-name {
  font-size: 0.8rem;
  margin-left: 1.15rem;
  margin-top: 5px;
  font-weight: bold;
}
.barhint-details :nth-child(3) .step-name {
  margin-left: 0.5rem;
}
.barhint-details .step .circle {
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
  font-size: 0.9em;
  background: var(--krab-color-light);
  font-weight: bold;
}

.barhint-details .step .line {
  width: 50px;
  height: 2px;
  background: var(--krab-color-light);
}

.barhint-details .project-step-container.--is-active .circle,
.barhint-details .project-step-container.--is-active .line {
  background: var(--krab-color-success);
  color: #fff;
}
.barhint-details .project-step-container.--is-active .step-name {
  color: var(--krab-color-success);
}

/* modal css */
.modal-header {
  height: 10%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #eeeded;
  font-weight: bold;
  background: var(--krab-color-ternary);
}

.modal-body {
  position: relative;
  height: 80%;
  overflow: hidden;
  background: #fff;
}

.circle-icon-container {
  background: rgba(233, 229, 229, 0.7);
  border-radius: 50%;
  color: gray;
  border: 1px solid rgba(233, 229, 229, 0.7);
  padding: 10px 13px;
  font-size: 0.7rem;
}

.custom-pkrab-over {
  position: absolute;
  z-index: 5;
  /* top: calc(100% - 4px); */
  bottom: 40px;
  right: 0;
  border-radius: 5px;
  border-top: none;
  box-shadow: 0 8px 8px rgb(10 10 10 / 10%), 0 0 0 1px rgb(10 10 10 / 10%);
  display: block;
  opacity: 0;
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
  pointer-events: none;
  transform: translateY(-5px);
  transition-duration: 86ms;
  transition-property: opacity, transform;
  background-color: #ffffff;
}
.custom-pkrab-over.--is-active {
  opacity: 1;
  pointer-events: all;
}

.custom-pkrab-over ul {
  list-style: none;
  padding: 0;
  margin: 3px 0;
}

.custom-pkrab-over ul li {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 0.85rem;
  white-space: nowrap;
  padding: 0.5em 1rem;
  cursor: pointer;
}

.click-effect{
  cursor: pointer;
}

.click-effect:active {
  transform: scale(0.9) !important;
  /* Scaling button to 0.98 to its original size */
  /* box-shadow: 3px 2px 22px 1px rgba(0, 0, 0, 0.24); */
  /* Lowering the shadow */
}

.pointer {
  cursor: pointer !important;
}

.custom-pkrab-over ul li span {
  margin-top: -3px;
  margin-left: 10px;
}

.custom-pkrab-over ul li:hover {
  background: #efefef;
}

.light-background {
  background: var(--krab-color-light);
}

.display-flex-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.display-flex-column {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}
.font-md-small {
  font-size: 0.9rem !important;
}
.font-smaller {
  font-size: 0.7rem !important;
}
.font-ex-small {
  font-size: 0.6rem !important;
}
.font-small {
  font-size: 0.8rem !important;
}
.font-ex-large {
  font-size: 2rem !important;
}
.font-large {
  font-size: 1.5rem !important;
}
.font-medium {
  font-size: 1.2rem !important;
}
.font-normal {
  font-size: 1rem !important;
}

.padding-start {
  padding-left: 10px;
}

.flex1 {
  flex: 1;
}

.custom-tabs-wrapper {
  display: flex;
  flex-direction: row;
}

.custom-tabs-wrapper .tab {
  padding: 14px;
  font-size: 0.9rem;
  font-weight: 550;
  flex: 50%;
  text-align: center;
  opacity: 0.5;
  cursor: pointer;
  color: gray;
  text-transform: initial;
}

.custom-tabs-wrapper .tab:hover {
  opacity: 1;
  color: black;
}

.position-relative {
  position: relative;
}

.custom-upload-input {
  width: 160px;
  padding: 12px;
  display: flex;
  font-size: 0.8rem;
  overflow: hidden;
  flex: 2;
}
.overflow-hidden {
  overflow: hidden;
}
.uppercase {
  text-transform: uppercase !important;
}

.capitalize {
  text-transform: capitalize !important;
}

.custom-tabs-wrapper .tab.--is-active {
  opacity: 1;
  /* border-bottom: 1px solid black; */
  color: black;
  background: rgba(233, 229, 229, 0.4);
}

.line-height-1{
  line-height: 1;
}

.heading-large{
  font-size: 24px;
  font-weight: 800;
}

.heading-medium{
  font-size: 18px;
  font-weight: 700;
}

.heading-small{
  font-size: 14px;
  font-weight: 600;
}

.heading {
  font-weight: 400;
  color: rgba(13, 27, 62, 0.7);
}
.header-heading{
  font-size: 1.2rem;
  font-weight: 700;
}

.heading1 {
  font-size: 16px;
  font-weight: 800;
  letter-spacing: 0.4px;
  color: #000;
}

.letter-spacing1{
  letter-spacing: 1px;
}

.letter-spacing12{
  letter-spacing: 1.2px;
}
.heading2,.heading2>span {
  letter-spacing: 0.4px;
  /* color: var(--op-color-danger); */
  font-size: 15px;
  font-weight: 700;
}

.heading3 {
  font-size: 14px;
  font-weight: 500;
  color: #000;
}

.heading4{
  letter-spacing: 0.4px;
  font-size: 12px;
  font-weight: 400;
  color: var(--op-color-dark);
}

.bold{
  font-weight: bold !important;
}
.underline{
  text-decoration: underline;
}
.normal{
  font-weight: normal !important;
}

.semibold{
  font-weight: 600;
}

.share-link-input {
  border: none;
  color: blue;
  text-decoration: underline;
}

.share-link-input:focus-visible {
  outline: none;
}
.share-link-input:active {
  border: none;
}


.full-height-width {
  height: 100%;
  width: 100%;
}

.modal-footer {
  height: 10%;
  display: flex;
  justify-content: center;
  padding: 0 10px;
  align-items: center;
  background: #fff;
  border-top: 1px solid rgba(233, 229, 229, 0.7);
}

.modal-header .modal-close {
  font-size: 2rem;
  position: absolute;
  right: 0;
  top: 0;
  padding: 10px;
}

.modal-close-icon {
  position: absolute;
  font-size: 1.5rem;
  top: 10px;
  right: 10px;
}

.custom-col-50 {
  flex: 50%;
  position: relative;
  height: 100%;
}
.custom-col-70 {
  flex: 70%;
  position: relative;
  height: 100%;
}
.custom-col-60 {
  flex: 60%;
  position: relative;
  height: 100%;
}
.custom-col-40 {
  flex: 40%;
  position: relative;
  height: 100%;
}
.custom-col-20 {
  flex: 20%;
  position: relative;
  height: 100%;
}
.custom-col-10 {
  flex: 10%;
  position: relative;
  height: 100%;
}
.custom-col-100 {
  flex: 100%;
  position: relative;
  height: 100%;
}

.add-action-button {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin: 40px;
}
.add-action-button-container {
  z-index: 1;
}
.absolute{
  position: absolute;
}
.marginleft5 {
  margin-left: 5px;
}
.marginleft10 {
  margin-left: 10px;
}
.margin0{
  margin: 0 !important;
}
.marginRight5 {
  margin-right: 5px;
}
.marginRight10{
  margin-right: 10px;
}
.marginTop3{
  margin-top: 3px;
}
.marginTop5{
  margin-top: 5px;
}
.marginTop10{
  margin-top: 10px;
}
.marginTop20{
  margin-top: 20px;
}
.marginTop15{
  margin-top: 15px;
}
.dropdown-select {
  display: inline-block;
  font-weight: 600;
  color: #495057;
  /* background-color: var(--krab-color-ternary); */
  padding: 0.425rem 0.75rem;
  line-height: 1.5;
  font-size: 0.9rem;
  text-transform: capitalize;
  position: relative;
  width: 100%;
  border: 1px solid var(--krab-color-dark);
  /* border-radius: .25rem; */
}
.dropdown-select:hover span,
.dropdown-select:focus span {
  color: var(--krab-color-info) !important;
}
.dropdown-select:hover,
.dropdown-select:focus {
  color: var(--krab-color-info) !important;
  border-color: var(--krab-color-info);
}

.dropdown-select .icon {
  display: flex;
  align-items: center;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  display: none;
  float: left;
  width: 100%;
  /* width: 300px; */
  padding: 0.65rem 0;
  margin: 0.125rem 0 0;
  font-size: 0.88rem;
  color: #495057;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  box-shadow: 0 0.46875rem 2.1875rem rgb(4 9 20 / 3%),
    0 0.9375rem 1.40625rem rgb(4 9 20 / 3%),
    0 0.25rem 0.53125rem rgb(4 9 20 / 5%), 0 0.125rem 0.1875rem rgb(4 9 20 / 3%);
  padding: 0.65rem;
  max-height: 300px;
  overflow-y: scroll;
  overflow-x: hidden;
  z-index: 1002;
}

.dropdown-menu.--is-active {
  display: block;
}

.dropdown-menu .dropdown-item {
  display: flex;
  align-items: center;
  transition: 0.3s ease, color 0.3s ease;
  cursor: pointer;
  z-index: 6;
  position: relative;
  padding: 7px 2px;
  font-size: 0.9rem;
  /* border-bottom: 1px solid var(--krab-color-light); */
}

.dropdown-menu .dropdown-item.--is-active {
  color: var(--krab-color-info);
  font-weight: bold;
}

.dropdown-menu .dropdown-item-container {
  padding: 5px 5px;
  overflow: hidden;
}
.dropdown-item {
  display: block;
  width: 100%;
  /* padding: .4rem 0.5rem; */
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}

.dropdown-item.active,
.dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: var(--krab-color-ternary);
}

.dropdown-item:hover,
.dropdown-item:focus {
  color: var(--krab-color-info);
  text-decoration: none;
  font-weight: bold;
  outline: none;
  background-color: var(--krab-color-active);
}

.dropdown-toggle::after {
  position: absolute;
  right: 5px;
  opacity: 0.8;
  top: 44%;
  display: inline-block;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
  transform: translate(-50%, 0);
}

.drkrab-down-label {
  position: absolute;
  bottom: 70%;
  left: 0.4rem;
  /* background: white; */
  /* padding: 0 5px; */
}

.drkrab-down-label .label {
  padding: 0 5px;
  background: white;
}

.drkrab-down-label.nested {
  bottom: 76%;
}

.nested-filter-wrapper {
  width: auto;
  min-width: 170px;
  /* z-index: 1; */
}

.nested-filter-parent-container {
  top: 100% !important;
  padding: 0;
  height: 350px;
  overflow-y: auto;
  z-index: 1002;
  display: none;
}

.nested-filter-child-container {
  right: -100%;
  padding: 0;
  top: 100%;
  height: auto;
  width: 170px;
  display: none;
  position: fixed;
}

.nested-filter-parent-container > div > div,
.nested-filter-child-container > div > div {
  color: inherit;
}

.nested-filter-parent-container > div:hover,
.nested-filter-child-container > div:hover {
  background: var(--krab-color-ternary);
  color: var(--krab-color-primary);
  font-weight: bold;
}

.nested-filter-title {
  border-color: var(--krab-color-dark);
  padding: 0.375rem 0.75rem;
}

.text-start {
  text-align: start !important;
}

.space-between {
  justify-content: space-between;
}

.space-around {
  justify-content: space-around;
}

.text-center {
  text-align: center;
}

.no-shadow {
  box-shadow: none !important;
}
.toast {
  visibility: hidden;
  background-color: rgba(39, 38, 38, 0.95);
  color: #fff;
  text-align: center;
  border-radius: 4px;
  padding: 10px 15px;
  position: absolute;
  left: 50%;
  bottom: -100px;
  border-radius: 5px;
  transform: translate(-50%, 0);
  z-index: 1001;
  transition: all 0.5s;
  min-width: 250px;
}

.toast .text {
  padding: 0 10px;
  font-weight: 500;
  color: #fff;
}

.toast.show {
  visibility: visible;
  bottom: 100px;
  /* -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
  animation: fadein 0.5s, fadeout 0.5s 2.5s; */
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

.krab-scrollbar::-webkit-scrollbar {
  width: 5px;
}

@-webkit-keyframes fadein {
  from {
    top: 0;
    opacity: 0;
  }
  to {
    bottom: 75px;
    opacity: 1;
  }
}

@keyframes fadein {
  from {
    bottom: 0;
    opacity: 0;
  }
  to {
    bottom: 75px;
    opacity: 1;
  }
}

@-webkit-keyframes fadeout {
  from {
    bottom: 75px;
    opacity: 1;
  }
  to {
    bottom: 0;
    opacity: 0;
  }
}

@keyframes fadeout {
  from {
    bottom: 75px;
    opacity: 1;
  }
  to {
    bottom: 0;
    opacity: 0;
  }
}

.tooltip {
  position: relative;
}

.tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: #000;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
}

.summary-tooltip {
  animation-name: tootltopAnimation;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  left: 50%;
  bottom: 115%;
  transform: translate(-50%, 0);
}
.tooltiptext.--is-active {
  visibility: visible;
}

.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #000 transparent transparent transparent;
}

@keyframes tootltopAnimation {
  0% {
    bottom: 115%;
  }
  50% {
    bottom: 135%;
  }
  100% {
    bottom: 115%;
  }
}

.tkrab-right {
  position: absolute;
  top: 0;
  right: 0;
  padding: 1rem;
}
.tkrab-left {
  position: absolute;
  top: 0;
  left: 0;
  padding: 1rem;
}

.box-shadow {
  box-shadow: 0 0.46875rem 2.1875rem rgb(4 9 20 / 3%),
    0 0.9375rem 1.40625rem rgb(4 9 20 / 3%),
    0 0.25rem 0.53125rem rgb(4 9 20 / 5%), 0 0.125rem 0.1875rem rgb(4 9 20 / 3%);
}

.no-wrap {
  flex-wrap: nowrap;
}

.custom-row {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.custom-col-30 {
  width: 30%;
}

.custom-col-80 {
  width: 80%;
}

.marginBottom5 {
  margin-bottom: 5px;
}

.coming-soon {
  position: relative;
  background-color: rgb(245, 245, 245);
  pointer-events: none;
  opacity: 0.5;
}

.coming-soon::after {
  content: "";
  white-space: pre;
  color: var(--krab-color-warning);
  color: #000;
  font-weight: bold;
  /* background-color: white; */
  position: absolute;
  top: 10px;
  left: 0;
  padding: 0 5px;
  opacity: 1;
  font-size: 0.6rem;
  transform: translate(-50%, -50%);
  transform: rotate(-45deg);
}

.right-middle {
  position: absolute;
  top: 50% !important;
  right: 10px;
  transform: translate(0, -50%);
}
.left-middle {
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translate(0, -50%);
}

.bottom-middle {
  position: absolute !important;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0);
}
.bottom-right {
  position: absolute !important;
  bottom: 0;
  right: 0;
}
.bottom-left {
  position: absolute !important;
  left: 0;
  bottom: 0;
}
.middle {
  position: absolute !important;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.tkrab-middle {
  position: absolute !important;
  top: 1rem;
  left: 50%;
  transform: translate(-50%, 0);
}

.right-bottom {
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 1rem;
}

.width50 {
  width: 50% !important;
}

.width40 {
  width: 40%;
}

.width30 {
  width: 30%;
}

.width60 {
  width: 60%;
}
.width70 {
  width: 70%;
}

.width80 {
  width: 80%;
}
.width20 {
  width: 20%;
}
.left-bottom {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 1rem;
}

.fixed-bottom-left {
  position: fixed !important;
  left: 0;
  bottom: 0;
}

.full-width-drkrab-down .dropdown-container {
  width: 100% !important;
}

.materials-filters-wrapper {
  height: auto;
}
.materials-wrapper {
  height: -webkit-fill-available;
  align-content: baseline;
  justify-content: baseline;
  padding-bottom: 12rem !important;
}
.dropdown-container {
  position: relative;
  height: auto;
  /* width: 200px; */
  width: auto;
  /* min-width: 150px; */
}

.disable-button {
  /* background: rgb(226, 217, 217); */
  opacity: 0.8;
  pointer-events: none;
}
.ar-button{
  /* background-image: url("/assets/images/aricon.png"); */
  background-repeat: no-repeat;
  background-size: 20px 20px;
  background-position: 12px 50%;
  background-color: #fff;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap;
  bottom: 0;
  padding: 0px 16px 0px 40px;
  font-family: Roboto Regular, Helvetica Neue, sans-serif;
  font-size: 14px;
  color: #4285f4;
  height: 36px;
  line-height: 36px;
  border-radius: 18px;
  border: 1px solid #DADCE0;
}

.ar-button i{
  color: #2874f0;
}


.new-badge-container::after {
  position: absolute;
  top: 0;
  right: 0;
  content: "New";
  padding: 1px 12px;
  color: white;
  font-size: 0.9rem;
  background: var(--krab-color-primary);
  box-shadow: 0 3px 5px 0px rgb(0 0 0 / 10%);
}

.new-badge-container .text {
  /* transform: rotate(-46Deg); */
  padding: 1px 12px;
  color: white;
  font-size: 0.9rem;
  background: var(--krab-color-primary);
}
.aspect-four-by-three {
  aspect-ratio: 4/3;
}

.hover-up:hover {
  cursor: pointer;
  transform: scale(1.02);
  transition: all 0.2s;
}

.rupee-sign {
  font-family: Roboto;
}

.overflow-x-scroll {
  overflow-x: auto;
}

.overflow-y-scroll {
  overflow-y: auto;
}
.overflow-y-hidden {
  overflow-y: hidden;
}
.logout-button {
  display: inline-block;
}
.header-title {
  display: inline-block;
  margin-top: 12px;
}

.krab-icon {
  font-size: 30px;
}

.full-width {
  width: 100% !important;

}

.full-height {
  height: 100%;
}

.height-inherit {
  height: inherit;
}

.main-loader {
  background: #fff;
  color: #000;
}

.shadow-light {
  box-shadow: 0 1px 1px 1px rgb(0 0 0 / 5%) !important;
}

.shadow-medium {
  /* box-shadow: 0 3px 5px 0px rgb(0 0 0 / 10%) !important; */
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px !important;
}

#screenshotClone {
  width: fit-content;
}

.left-controls-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  color: white;
}

.left-menu-top {
  background-color: rgb(247, 138, 138);
  width: 5%;
  text-align: center;
}

.left-controls-top {
  background-color: #007bff;
  width: 95%;
}

.krab-icon-left {
  font-size: 30px;
  padding: 10px;
}

 

.hr-scroll-icon-wrapper{
    /* position: fixed; */
    /* top: -1rem;
    left: -1rem; */
    background: rgba(255,255, 255, 0);
    z-index: 30;
    padding: 0 0.6rem;
}
 
.hr-scroll-icon-wrapper .icon{
   color: var(--krab-color-danger);
   font-size: 1.4rem;
   opacity: 0.4;
}

.hr-scroll-icon-wrapper .icon.--is-active{
  opacity: 1;
}


.texture-label-container{
  flex-direction: column;
  width: 25%;
  padding:0.2rem 0;
  margin: 3px 0;
}
.texture{
  padding: 6%;
  width: 100%;
}
.texture-details{
  padding: 0;
  text-align: center;
  width: 100%;
}

.texture-details>div{
  display: none;
}

.texture-details>div:nth-child(1){
  display: block;
}

.texture-details .heading{
  padding: 0 1rem;
    overflow: hidden;
    height: 15px;
}

.display-none{
  display: none;
}

.background-black-fade{
  background: rgba(0, 0, 0, 0.7);
}


.background-white-fade{
  background: rgba(255, 255, 255, 0.5);
}

.card-padding{
  padding: 0.7rem 1.5rem;
}

.width-max-content{
  width: max;
}

.no-margin{
  margin: unset !important;
}

@media screen and (max-width:480px){
  .texture-label-container{
      width: 25%;
  }
  .width40 {
    width: 100%;
  }
  
  .width60 {
    width: 100%;
  }

  .product-filters .nested-filter-wrapper {
    /* width: 100%; */
  }

  .product-filters .nested-filter-title{
    padding: 0.5rem 0.75rem;
  }
  
 
}

@media screen and (min-width:481px) and (max-width:1024px){
 
  
}


