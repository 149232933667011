.main-header-wrapper,.customizer-header-wrapper{
    text-transform: unset;
    letter-spacing: unset;
    overflow: hidden;
    width: 100%;
    height: var(--main-header-height); 
    box-shadow: 0 3px 5px 4px rgb(0 0 0 / 10%) !important;
    z-index: 1001;
    position: relative;
    background-color: var(--krab-color-light) !important;
}

 
.header-left-content{
    padding: 0 5px;
}

.header-middle-content{
    justify-content: center;
    height: 100%;
    width: 60%;
    text-align: center;
    display: flex;
    align-items: center;
    cursor: pointer;
}
 

.header-left-content{
    width: auto;
    justify-content: left;
    font-size: 2rem;
    display: flex;
    justify-content: start;
    align-items: center;
}

.header-right-content{
    width: auto;
    text-align: end;
    font-size: 0.9rem;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}


.brand{
    font-size: 1rem;
    font-weight: 500;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 10px;
    cursor: pointer;
  }

  .brand img{
      max-height: 40px;
  }
  
  .tagline{
    font-size: 0.7rem;
    margin-top: 5px;
    color: #818181;
    margin-left: 3px;
  }

.user-info{
    display: flex;
    flex-direction: row;
    font-size: 0.9rem;
    height: 100%;
    align-items: center;
}

.user-info>div{
    padding: 0 5px;
}

.user-info::after{
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    content: "\f105";
    font-style: normal;
    display: inline-block;
    font-style: normal;
    font-feature-settings: normal;
    font-variant: normal;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    background: 0 0;
    transform: rotate(90deg);
    margin-top: 5px;
}


.nav-container{
    display: flex;
    height: inherit;
    flex-direction: row;
    justify-content: start;
}

.nav-container>div{
    height: inherit;
    color: gray;
    padding: 0 20px;
    font-weight: bold;
    cursor: pointer;
    font-size: 0.8rem;
}

.nav-container>div:hover{
    color: var(--op-color-primary);
}

.nav-item.--is-active{
    color: var(--op-color-primary);
}

.nav-item .count{
    padding: 0 10px;
    border-radius: 10px;
    margin-left: 5px;
    font-size: 0.9rem;
}

.user-options{
    position: absolute;
    z-index: 5;
    /* top: calc(100% - 4px); */
    top: 50px;
    right: 0;
    border-radius: 5px;
    border-top: none;
    box-shadow: 0 8px 8px rgb(10 10 10 / 10%), 0 0 0 1px rgb(10 10 10 / 10%);
    display: block;
    opacity: 0;
    padding-bottom: 0.5rem;
    padding-top: 0.5rem;
    pointer-events: none;
    transform: translateY(-5px);
    transition-duration: 86ms;
    transition-property: opacity, transform;
    background-color: #ffffff;
}

.user-options ul{
    list-style: none;
    padding: 0;
    margin: 3px 0;
}

.user-options ul li{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 0.85rem;
    white-space: nowrap;
    padding: 0.5em 1rem;
    cursor: pointer;
}

.user-options ul li:hover{
    background:#efefef;
}

.user-options ul li:last-child{
    border-top: 1px solid #efefef;
}

.user-options ul span{
    padding: 0 0.5rem;
}

.user-options .icon{
    margin-top: 2px;
}

.main-footer{
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 60px;
    border-radius: 25px 25px 0 0;
}

.main-footer .icon{
    width: 25px;
    margin: 0 20px 0 20px;
}

.main-footer .text{
    display: none;
}

.main-footer .icon-wrapper.--is-active .text{
    display: block;
}

.main-footer .icon-wrapper.--is-active .icon{
    margin-top: 10px;
}

@media screen and (max-width:480px){
    .user-name{
        display: none;
    }
    .user-info::after {
        content: "";
    }

    .header-middle-content{
        display: none;
    }
}

@media screen and (min-width:481px) and (max-width:1024px){
   

    .header-middle-content{
        width: 50%;
    }
    .nav-container>div{
        padding: 0 10px;
    }

}